<template>
  <div class="projTable">
    <el-table
      v-if="workRead || projectRead || operationRead"
      :class="{ show_empty: showEmpty }"
      v-loading="tableLoading"
      :data="tableData"
      :max-height="tableHeight"
      border
      style="width: 100%"
      empty-text=" "
      :row-key="routeName === '运营管理' ? 'id' : ''"
      :default-expand-all="routeName === '运营管理'"
      :tree-props="routeName === '运营管理' ? { children: 'children', hasChildren: 'hasChildren' } : {}"
      header-cell-class-name="header-row-cell"
    >
      <el-table-column
        v-for="(item, idx) in tableOperations"
        :key="idx"
        :label="$t('projectManagement.' + item.label + '')"
        align="left"
        :min-width="item.width"
      >
        <template slot="header" v-if="item.type === 'input'">
          <span
            >{{ $t('projectManagement.' + item.label + '') }}&nbsp;&nbsp;
            <el-tooltip class="item" effect="dark" content="可编辑" placement="top-start">
              <i class="el-icon-edit"></i>
            </el-tooltip>
          </span>
        </template>
        <template slot="header" v-if="item.type === 'inputNumber'">
          <span
            >{{ $t('projectManagement.' + item.label + '') }}&nbsp;&nbsp;
            <el-tooltip class="item" effect="dark" content="可编辑" placement="top-start">
              <i class="el-icon-edit"></i>
            </el-tooltip>
          </span>
        </template>
        <template slot="header" v-if="item.type === 'select'">
          <span
            >{{ $t('projectManagement.' + item.label + '') }}&nbsp;&nbsp;
            <el-tooltip class="item" effect="dark" content="可编辑" placement="top-start">
              <i class="el-icon-edit"></i>
            </el-tooltip>
          </span>
        </template>
        <template slot="header" v-if="item.desc">
          <span class="showTipsColumnSpan"
            >{{ $t('projectManagement.' + item.label + '') }}&nbsp;&nbsp;
            <el-popover placement="top-start" trigger="hover" class="showTips">
              <span> {{ item.desc }}</span>
              <el-button
                :class="['unactived', { actived: hoverType }]"
                class="btn-miss"
                slot="reference"
                @mouseenter.native="hoverCheck(1)"
                @mouseleave="hoverCheck(0)"
              >
              </el-button>
            </el-popover>
          </span>
        </template>
        <template slot-scope="scope">
          <div v-if="!item.type">
            <!-- justSettlement: 编辑服务项；servingJustSettlement：编辑工作项 -->
            <div :class="item.label === 'serviceItem' && (scope.row.justSettlement || scope.row.servingJustSettlement) ? 'tips' : ''">
              <span v-if="isServiceItem && scope.row.children && !scope.row.children.length"></span>
              <span>{{ scope.row[item.value] }}</span>
            </div>
          </div>
          <div v-else-if="item.type === 'input'" class="inputCommonStyle">
            <div class="scopeInputAccomplete" v-show="!inputTip(scope.$index)">
              <el-tooltip v-if="scope.row[item.key].length > 12" class="item" effect="dark" :content="scope.row[item.key]" placement="top-start">
                <div class="workInputShadow" @click="clickThisRow(scope.row, scope.$index)">
                  {{ scope.row[item.key] }}
                </div>
              </el-tooltip>
              <div v-else class="workInputShadow" @click="clickThisRow(scope.row, scope.$index)">
                {{ scope.row[item.key] }}
              </div>
            </div>
            <div v-show="inputShow && inputTip(scope.$index)" class="inputFatherStyle">
              <el-autocomplete
                ref="autoComplete"
                value-key="name"
                popper-class="inputAutocomplete"
                :label="scope.row[item.key]"
                :debounce="300"
                v-model="scope.row[item.key]"
                :fetch-suggestions="querySearch"
                placeholder="请输入内容"
                @select="selectQuery"
                @change="inputQuery"
              >
              </el-autocomplete>
            </div>
          </div>
          <div v-else-if="item.type === 'select'" class="selectCommonStyle">
            <div class="selectItem" v-show="!inputTip(scope.$index)">
              <el-tooltip
                v-if="getStandardWorkItem(scope.row.itemId).length > 12"
                class="item"
                effect="dark"
                :content="getStandardWorkItem(scope.row.itemId)"
                placement="top-start"
              >
                <div class="selectShadow" @click="clickThisRow(scope.row, scope.$index)">
                  {{ getStandardWorkItem(scope.row.itemId) }}
                </div>
              </el-tooltip>
              <div v-else class="selectShadow" @click="clickThisRow(scope.row, scope.$index)">
                {{ getStandardWorkItem(scope.row.itemId) }}
              </div>
            </div>

            <div v-show="inputShow && inputTip(scope.$index)" class="scope-select">
              <el-select
                size="mini"
                @change="updateTaskData(scope.row, scope.$index)"
                v-model="tableData[scope.$index][item.value]"
                filterable
                clearable
              >
                <el-option v-for="item in resourceOptions" :key="item.id" :label="item.name" :value="item.id"> </el-option>
              </el-select>
            </div>
          </div>
          <div v-else-if="item.type === 'inputNumber'">
            <div class="inputItem">
              <template v-if="!inputTip(scope.$index)">
                <div class="inputShadow" @click="clickThisRow(scope.row, scope.$index)">
                  {{ scope.row[item.value] }}
                </div>
              </template>
            </div>
            <div v-if="inputShow && inputTip(scope.$index)" class="scope-input">
              <el-input @change="updateTaskData(scope.row, scope.$index)" v-model="scope.row[item.value]"> </el-input>
            </div>
          </div>
          <div v-else-if="item.label === 'standardUnit'">
            <span>{{ getStandardUnit(scope.row.itemId) }}</span>
          </div>
        </template>
      </el-table-column>

      <el-table-column
        :label="$t('projectManagement.operation')"
        label-class-name="confirmLabel"
        :align="'center'"
        :width="$route.meta.name === '运营管理' ? '190px' : '180px'"
        fixed="right"
      >
        <template slot-scope="scope">
          <div class="operations">
            <!-- 运营 -->
            <span v-show="$route.meta.name === '运营管理' && operationUpdate" @click="copyRow(scope.row)">复制合同信息到空行</span>
            <div ref="editWorkItemOperation">
              <!-- 编辑任务项 -->
              <span v-show="rowEditJobItem" @click="editTaskItem(scope.row)">{{ $t('projectManagement.task.editTaskItem') }}</span>
              <!-- 删除任务项 -->
              <span v-show="workDelete && isWorkItem" @click="deleteWorkItem(scope.row)">{{ $t('projectManagement.delete') }}</span>
            </div>

            <!-- 没有编辑服务项权限 -->
            <div v-if="!editServiceItemAuth && isServiceItem">
              <span v-show="isServiceItem" @click="deleteRow(scope.$index, scope.row)">{{ $t('projectManagement.delete') }}</span>
              <span v-show="workEdit" @click="$emit('createProjectWorkItem', scope.row, 'edit')">{{
                $t('projectManagement.createProWorkItem')
              }}</span>
            </div>
            <!-- 有编辑服务项权限 -->
            <div v-else-if="(editServiceItemAuth && !isWorkShowItem) || $route.meta.name === '运营管理'" style="display: inline-block">
              <span v-show="rowEditServiceItem" @click="editServiceItem(scope.row)">{{ $t('projectManagement.editServiceItem') }}</span>
              <div v-show="!isWorkShowItem" class="serviceOperation">
                <el-popover placement="left" trigger="click" popper-class="popover">
                  <ul class="selectUl" style="text-align: center">
                    <li v-for="ids in operationItems" :key="ids" class="selectLi" @click="operationType(ids, scope.$index, scope.row)">
                      <span>{{ ids }}</span>
                    </li>
                  </ul>
                  <div class="pullMore" slot="reference">
                    <span class="text">...</span>
                  </div>
                </el-popover>
              </div>
            </div>
          </div>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import { mixinsProjectStatement } from '@/mixins/authGroup';

import projectManagementApi from '@/api/modules/projectManagement.api.js';

export default {
  mixins: [mixinsProjectStatement],

  props: {
    tableHeight: {
      type: Number,
      default: 0,
    },
    tableLoading: {
      type: Boolean,
      default: false,
    },
    checkFirstGetWork: {
      type: Boolean,
      default: false,
    },
    typeHeaderModule: {
      type: String,
      default: '',
    },
    originTableData: {
      type: Array,
      default: () => {
        return [];
      },
    },
    matchArr: {
      type: Array,
      default: () => {
        return [];
      },
    },
    resourceOptions: {
      type: Array,
      default: () => {
        return [];
      },
    },
    serviceTableData: {
      type: Array,
      default: () => {
        return [];
      },
    },

    tableOperations: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  /* 待启用 */
  created() {
    this.checkAuthOperation();
  },
  activated() {
    this.checkAuthOperation();
  },
  data() {
    return {
      currentRow: {},
      inputShow: false,
      scopeIdx: null,
      taskData: [],
      isClick: false,
      operationItems: [],
      tableData: [],
      projectData: [],
      serviceId: '',
      resizeFlag: null,
      showEmpty: false,
      hoverType: 0,
    };
  },
  computed: {
    getStandardUnit() {
      return id => {
        const standardItem = this.resourceOptions.find(item => {
          return item.id === id;
        });
        if (standardItem) {
          return standardItem.unit;
        } else return '';
      };
    },
    getStandardWorkItem() {
      return id => {
        const standardItem = this.resourceOptions.find(item => {
          return item.id === id;
        });
        if (standardItem) {
          return standardItem.name;
        } else return '';
      };
    },
    permissionsKeys() {
      return this.$store.state.app.userConfig.permissionsKeys || {};
    },
    routeName() {
      return this.$route.meta.name;
    },
  },
  watch: {
    matchArr: {
      deep: true,
      immediate: false,
      handler() {
        this.matchList();
      },
    },
    originTableData: {
      deep: true,
      immediate: false,
      handler(val) {
        !val.length ? (this.showEmpty = true) : (this.showEmpty = false);
        if (this.$route.meta.name === '项目管理' && this.typeHeaderModule === this.$t('projectManagement.editServiceItem')) {
          let templateData = JSON.parse(JSON.stringify(val));
          this.tableData = JSON.parse(JSON.stringify(val));

          templateData.forEach(item => {
            if (item.children?.length) {
              const childrenData = [...item.children];
              let tableIdx = this.tableData.findIndex(i => {
                return i.id === item.id;
              });
              this.tableData.splice(tableIdx, 1);
              this.tableData.splice(tableIdx, 0, ...childrenData);
            }
          });

          this.projectData = [...val];
        } else {
          this.tableData = [...val];
        }
      },
    },
    // 利用checkFirstGetWork的变化(数据刷新重置)来初始化table的各个状态指标
    // checkFirstGetWork为false的变化，牢牢地和编辑工作项的服务项刷新同步
    checkFirstGetWork: {
      deep: true,
      immediate: false,
      handler(val) {
        if (!val) {
          this.currentRow = {};
          this.inputShow = false;
          this.scopeIdx = null;
          this.taskData = [];
          this.$emit('transformTaskData', []);
        }
      },
    },
  },
  methods: {
    matchList() {
      const filterIds = this.taskData.map(i => {
        return i.id;
      });
      const hasChangeItem = this.matchArr.find(val => {
        return filterIds.includes(val.id);
      });
      const filterMatchArr = [...this.matchArr];
      if (hasChangeItem) {
        this.$confirm(this.$t('projectManagement.confirmToMatch'), this.$t('projectManagement.note'), {
          confirmButtonText: this.$t('commonVariables.complete'),
          cancelButtonText: this.$t('commonVariables.cancel'),
        })
          .then(async () => {
            this.taskData.map((item, idx) => {
              filterMatchArr.find((val, newIdx) => {
                if (val.id == item.id) {
                  this.taskData[idx].itemId = val.itemId;
                  filterMatchArr.splice(0, newIdx);
                }
                return val.id === item.id;
              });
            });
            this.tableData.map((i, vIdx) => {
              filterMatchArr.find(arrV => {
                if (arrV.id == i.id) {
                  this.tableData[vIdx].itemId = arrV.itemId;
                  this.taskData.push({
                    id: arrV.id,
                    name: i.name,
                    itemId: arrV.itemId,
                    size: i.size,
                  });
                }
                return;
              });
            });
          })
          .catch(() => {});
      } else {
        this.tableData.map((i, vIdx) => {
          filterMatchArr.find(arrV => {
            if (arrV.id == i.id) {
              this.tableData[vIdx].itemId = arrV.itemId;
              this.taskData.push({
                id: arrV.id,
                name: i.name,
                itemId: arrV.itemId,
                size: i.size,
              });
            }
            return;
          });
        });
      }
      this.$emit('transformTaskData', this.taskData);
    },
    editServiceItem(row) {
      this.$emit('editShowDialog', 1, row.id);
    },
    editTaskItem(row) {
      this.$emit('editTaskDrawer', row);
    },
    deleteWorkItem(row) {
      this.$confirm('<p>此操作将删除该任务项, 是否继续?</p>', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        dangerouslyUseHTMLString: true,
      }).then(() => {
        this.$emit('deleteWorkItem', row.id);
      });
    },
    inputTip(idx) {
      return this.scopeIdx === idx;
    },
    clickThisRow(row, idx) {
      this.currentRow = row;
      this.scopeIdx = idx;
      this.inputShow = true;
    },

    updateTaskData(row, idx) {
      const findIdx = this.taskData.findIndex(val => {
        return val.id === row.id;
      });
      const currentItem = this.tableData[idx];
      if (!~findIdx) {
        this.taskData.push({
          id: currentItem.id,
          name: currentItem.name,
          itemId: currentItem.itemId,
          size: currentItem.size,
        });
      } else {
        this.taskData[findIdx] = {
          id: currentItem.id,
          name: currentItem.name,
          itemId: currentItem.itemId,
          size: currentItem.size,
        };
      }
      this.$emit('transformTaskData', this.taskData);
    },
    selectQuery(choosedItem) {
      this.inputQuery(choosedItem);
    },
    inputQuery(choosedItem) {
      const idx = this.scopeIdx;
      let changeItem = null;
      let choosedName = '';
      if (typeof choosedItem === 'object') {
        changeItem = Object.assign({}, choosedItem);
        choosedName = choosedItem.name;
      } else {
        choosedName = choosedItem;
      }
      // this.tableData[idx].id = choosedItem.id;
      this.tableData[idx].name = choosedName;
      // 警告！必须针对清空项进行特殊操作！避免存在多行的项目工作项为空时索引失败！
      // findIdx查找是否已经录入到taskData
      const findIdx = this.taskData.findIndex(updateItem => {
        return updateItem.id === this.tableData[idx].id;
      });

      if (!this.tableData[idx].name) {
        this.tableData[idx].itemId = '';
        if (!~findIdx) {
          this.taskData.push({
            id: this.tableData[idx].id,
            name: '',
            itemId: '',
            size: this.tableData[idx].size,
          });
        } else {
          this.taskData[findIdx] = {
            id: this.tableData[idx].id,
            name: '',
            itemId: '',
            size: this.tableData[idx].size,
          };
        }
        this.$emit('transformTaskData', this.taskData);
        return;
      }
      this.tableData[idx].itemId = changeItem?.id;

      // 在提交项里面寻找对应被修改的那一行是否存在
      // 若不存在，直接push一个新的当前行对象
      // itemId在上文中是存在动态变更的情况，所以itemId要对准this.tableData[idx]的实时更新，而不是更前一步获取的this.tableData[idx]
      if (!~findIdx) {
        this.taskData.push({
          id: this.tableData[idx].id,
          name: this.tableData[idx].name,
          itemId: changeItem?.id,
          size: this.tableData[idx].size,
        });
      } else {
        // 若存在则进行全量覆盖修改
        this.taskData[findIdx] = {
          id: this.tableData[idx].id,
          name: this.tableData[idx].name,
          itemId: changeItem?.id,
          size: this.tableData[idx].size,
        };
      }
      this.$emit('transformTaskData', this.taskData);
    },
    querySearch(queryString, cb) {
      const customerEqs = [...this.resourceOptions];
      const results = queryString ? customerEqs.filter(this.createFilter(queryString)) : customerEqs;
      // 调用 callback 返回建议列表的数据
      cb(results);
    },
    createFilter(queryString) {
      return customerEq => {
        return customerEq.name.toLowerCase().indexOf(queryString.toLowerCase()) === 0;
      };
    },
    checkAuthOperation() {
      this.operationItems = [];
      if (this.$route.meta.name === '运营管理') {
        if (this.operationUpdate) {
          this.operationItems.push('编辑服务项');
        }
        if (this.operationDelete) {
          this.operationItems.push('删除');
        }
      } else {
        /*         if (this.permissionsKeys.JobManagementRead) {
          this.operationItems.push('编辑任务项');
        } */
        if (this.isServiceItem) {
          if (this.workEdit) {
            this.operationItems.push('新建项目工作项');
          }
          this.operationItems.push('删除');
        }
      }
    },
    operationType(val, index, rows) {
      switch (val) {
        case '删除':
          this.deleteRow(index, rows);
          break;
        case '编辑服务项':
          this.$emit('editShowDialog', 1, rows.id);
          break;
        case '编辑任务项':
          this.$emit('editTaskDrawer', rows);
          break;
        case '新建项目工作项':
          if (this.workEdit) {
            this.$emit('createProjectWorkItem', rows, 'edit');
          }
          break;
        /*         case '编辑明细':
          this.$emit('editShowDialog', 0, rows.id);
          break; */
      }
    },

    isShow(bool, row) {
      if (bool) {
        this.isClick = true;
        this.serviceId = row.id;
      } else {
        this.isClick = false;
        this.serviceId = '';
      }
    },

    //复制合同信息到空行
    copyRow(row) {
      let flag = false;
      let filterTableData = this.routeName === '运营管理' ? this.tableData : this.projectData;
      for (let blankRow of filterTableData) {
        if (blankRow.children.length) {
          const childs = [...blankRow.children];
          childs.forEach((item, idx) => {
            if (item.projectCode === '' && row.projectCode !== '') {
              flag = true;
              blankRow.children[idx].projectCode = row.projectCode;
            }
            if (item.projectSeq === '' && row.projectSeq !== '') {
              flag = true;
              blankRow.children[idx].projectSeq = row.projectSeq;
            }
          });
        }
        if (blankRow.projectCode === '' && row.projectCode !== '') {
          flag = true;
          blankRow.projectCode = row.projectCode;
        }
        if (blankRow.projectSeq === '' && row.projectSeq !== '') {
          flag = true;
          blankRow.projectSeq = row.projectSeq;
        }
      }
      if (row.projectCode == '' && row.projectSeq == '') {
        this.$message.warning('复制内容为空！');
      } else if (flag == true) {
        this.$message.success('复制成功,点击保存后生效!');
        this.$emit('loadTableData', filterTableData);
      } else {
        this.$message.warning('没有空行！');
      }
    },

    deleteRow(index, rows) {
      this.$confirm('<p>此操作将删除该服务项, 是否继续?</p>', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        dangerouslyUseHTMLString: true,
      }).then(async () => {
        const res = await projectManagementApi.verifyServiceItemTimeFilling(rows.id);
        if (res[0].exists) {
          this.$alert('该服务项已有工时，无法删除。', '提示', {
            confirmButtonText: '确定',
            type: 'error',
          });
          return;
        }
        if (res[0].scheduleExists) {
          this.$alert('该服务项已有进度，无法删除。', '提示', {
            confirmButtonText: '确定',
            type: 'error',
          });
          return;
        }
        this.$emit('deleteRow', rows.id);
      });
      /*         .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除！',
          });
        }); */
    },
    hoverCheck(item) {
      this.hoverType = item;
    },
  },
};
</script>
<style lang="scss" scoped>
@import '@/styles/compsStyle/projectManagementTable.scss';
.projTable {
  @include projTableClass;
}
.serviceOperation {
  width: 16px;
  height: 16px;
  .pullMore {
    margin-top: -2px;
    margin-left: -12px;
  }
}
.tips::after {
  position: absolute;
  content: '';
  top: 0;
  right: 0;
  width: 0;
  height: 0;
  border: 4px solid #f59a23;
  border-left-color: transparent;
  border-bottom-color: transparent;
}
.showTipsColumnSpan {
  height: 32px;
  line-height: 32px;
  display: inline-block;
  .showTips {
    padding-top: 2px;
    .btn-miss {
      border: 0 !important;
      background: #f8f8f8f9;
    }
    .unactived {
      position: relative;
      cursor: pointer;
      padding: 0;
      border-radius: 4px;
      border: 1px solid #e8e8e8;
    }
    .actived {
      border: 1px solid rgba(24, 144, 255, 0.2);
    }
    .unactived::before {
      content: '';
      position: absolute;
      left: 20%;
      transform: translateY(-80%);
      width: 12px;
      height: 15px;
      background: url('~@/assets/tabIcon/tip.png') no-repeat;
      background-size: cover;
    }
    .unactived:hover::before {
      vertical-align: center;
      background: url('~@/assets/tabIcon/tip_actived.png') no-repeat;
      background-size: cover;
    }
  }
}
</style>
<!-- 以下代码修改 popover 样式，不可以使用 scoped -->
<style lang="scss">
.popover {
  padding: 0;
  min-width: 120px;
  .selectUl {
    .selectLi {
      cursor: pointer;
      height: 30px !important;
      line-height: 30px !important;
      border-bottom: 1px solid #f3f4f5;
      span {
        font-size: 13px;
      }
    }
    .selectLi:hover {
      color: #2d8cf0;
      background-color: #ecf5ff;
    }
    .selectLi:last-child {
      border-bottom: none;
    }
  }
}
</style>
