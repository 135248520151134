<template>
  <div class="editServiceTable" :style="{ height: tableContainerHeight }">
    <el-table
      ref="taskTable"
      :class="{ show_empty: showEmpty }"
      v-loading="tableLoading"
      :data="taskTableData"
      v-on="$listeners"
      highlight-current-row
      @select="selectNode"
      :drawerType="drawerType"
      @select-all="selectAll"
      border
      :row-style="{ height: 23 + 'px' }"
      empty-text=" "
      :max-height="tableHeight"
    >
      <el-table-column class="selectionType" type="selection" width="40" v-if="drawerType === 'edit'"> </el-table-column>

      <el-table-column
        v-for="(item, index) in chooseInputArr"
        :key="index + 50"
        :align="item.align || 'left'"
        :min-width="item.width ? item.width : '150'"
        :show-overflow-tooltip="item.tooltip"
      >
        <template #header>
          <span style="padding-right: 5px">{{ $t(applyComp + '.' + item.prop) }}</span>
        </template>
        <template slot-scope="scope">
          <el-tooltip
            v-if="integratedData(scope.row[item.prop], item.prop).length > 28"
            :content="integratedData(scope.row[item.prop], item.prop)"
            class="item"
            effect="dark"
            placement="top"
          >
            <span class="headerClass" style="padding-right: 5px">{{ integratedData(scope.row[item.prop], item.prop) }}</span>
          </el-tooltip>
          <span v-else>{{ integratedData(scope.row[item.prop], item.prop) }}</span>
        </template>
      </el-table-column>
      <el-table-column :label="$t('commonVariables.operate')" fixed="right" align="center" min-width="80" v-if="drawerType === 'edit'">
        <template slot-scope="scope">
          <div class="operations" fixed="right">
            <!-- <span v-if="readPermission" @click="checkRow(scope.$index, taskTableData)" class="editRow">查看</span> -->
            <span
              v-if="authGroup.update || (!authGroup.update && !scope.row.assigns.length)"
              @click="editRow(scope.$index, taskTableData)"
              class="editRow"
              >{{ $t('commonVariables.edit') }}</span
            >
            <span v-if="authGroup.delete" @click="deleteRow(scope.$index, taskTableData)" class="deleteRow">{{ $t('commonVariables.delete') }}</span>
            <span v-if="!visitorAuth">无操作权限</span>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      class="pagination"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="page"
      :page-sizes="[10, 20, 30, 50, 100]"
      :page-size="pagesize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="count"
    >
    </el-pagination>
  </div>
</template>

<script>
import taskApi from '@/api/modules/task.api';

export default {
  computed: {
    tableContainerHeight() {
      return this.tableHeight + 60 + 4 + 'px';
    },
    routeName() {
      return this.$route.meta.name;
    },

    visitorAuth() {
      return Object.values(this.authGroup).find(val => val);
    },
  },
  props: {
    page: Number,
    currentPage: Number,
    pagesize: Number,
    count: Number,

    authGroup: {
      type: Object,
      default: () => {
        return {};
      },
    },
    applyComp: String,
    tableHeight: {
      type: Number,
      default: 0,
    },
    tableLoading: {
      type: Boolean,
      default: false,
    },
    readWeightAuth: {
      type: Boolean,
      default: false,
    },
    taskTableData: {
      type: Array,
      default: () => {
        return [];
      },
    },
    drawerType: {
      type: String,
      default: '',
    },
    chooseInputArr: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  data() {
    return {
      deleteWarnTips: '',
      transformType: '',
      id: '',
      showEmpty: false,
      isClick: false,
      serviceId: false,
      currentRoute: '',
    };
  },

  watch: {
    taskTableData: {
      deep: true,
      immediate: false,
      handler(newVal) {
        !newVal.length && (this.showEmpty = true);
      },
    },
  },

  methods: {
    selectAll(selection) {
      this.$emit('selectionAll', selection);
    },
    selectNode(selection) {
      this.$emit('selection', selection);
    },
    integratedData(prop, propType) {
      if (prop instanceof Array) {
        if (!prop.length) {
          return '';
        }
        if (propType === 'assigns') {
          return prop
            .map(item => {
              let assignsDesc = item.name + ':' + item.role;
              if (this.readWeightAuth) {
                return assignsDesc + ':' + (item.weight * 100 + '%');
              } else {
                return assignsDesc;
              }
            })
            .join();
        }
      } else {
        return prop;
      }
      // <span>{{ && !scope.row[item.prop].length ? '' : scope.row[item.prop] }}</span>
    },
    checkFollow(row) {
      this.$emit('checkFollow', row);
    },
    circleStatus(val) {
      switch (val) {
        case 'doing':
          return '#2D8CF0';
        case 'finish':
          return '#DA4F2A';
      }
    },
    /*     isShow(bool, row) {
      if (bool) {
        this.isClick = true;
        this.serviceId = row.id;
      } else {
        this.isClick = false;
        this.serviceId = '';
      }
    }, */
    handleSizeChange(val) {
      this.$emit('pageChange', 'size', val);
    },
    handleCurrentChange(val) {
      this.$emit('pageChange', 'current', val);
    },

    recordProps(item) {
      this.$emit('record', item);
    },

    async checkRow(index, rows) {
      this.$emit('readServiceItem', rows[index]);
    },
    async editRow(index, rows) {
      this.$emit('editServiceItem', rows[index]);
    },
    async deleteRow(index, rows) {
      const delRow = rows[index];
      if (delRow.id) {
        const [delTips] = await taskApi.validateDeleteTask(delRow.id);
        if (!delTips.canDelete) {
          this.$message.error('任务项已有进度填写记录，无法删除');
          return;
        }
        this.deleteWarnTips = '请确认是否删除该任务项，删除后不可撤销';
      }

      this.$confirm(this.deleteWarnTips, '删除任务', {
        confirmButtonText: this.$t('commonVariables.complete'),
        cancelButtonText: this.$t('commonVariables.cancel'),
        type: 'warning',
        dangerouslyUseHTMLString: true,
      })
        .then(() => {
          if (!delRow.id) {
            rows.splice(index, 1);
            return;
          }
          if (delRow.id) {
            this.$emit('deleteData', delRow.id);
          }
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除！',
          });
        });
    },
  },
};
</script>
<style lang="scss" scoped>
@import '@/styles/compsStyle/projectStatement.scss';

.headerClass {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
::v-deep .el-table__row {
  .el-table__cell {
    padding-top: 12px;
    padding-bottom: 12px;
    .hours-input {
      font-size: 14px;
      .el-input__inner {
        color: #515a6e;
        border: none;
        text-align: left;
        height: 29px;
        line-height: 29px;
        background-color: #fff;
      }
    }
    .start::before {
      content: '';
      display: inline-block;
      vertical-align: middle;
      margin-right: 10px;
      width: 8px;
      height: 8px;
      background: #15ad31;
      border-radius: 50%;
    }
    .unStart::before {
      content: '';
      display: inline-block;
      vertical-align: middle;
      margin-right: 10px;
      width: 8px;
      height: 8px;
      background: #ff9810;
      border-radius: 50%;
    }
  }
}
.editServiceTable {
  height: 100%;
  padding: 12px 32px;
  padding-bottom: 0;
  background: #fff;
  margin: 0;
  overflow: hidden;
  border-radius: 2px;

  ::v-deep .el-table {
    color: #515a6e;
    .header-row-cell {
      border-bottom: none !important;
    }
    .el-table__fixed-header-wrapper {
      border-bottom: 1px solid #e8eaec;
      border-radius: 4px;
      .el-table__header th.el-table__cell {
        background-color: #f8f8f8f9;
        color: #515a6e;
        font-weight: 600;
        padding: 6px 0;
        height: 48px;
        box-sizing: border-box;
      }
    }
    ::v-deep .el-table__row {
      .el-table__cell {
        padding-top: 12px;
        padding-bottom: 12px;
        .hours-input {
          font-size: 14px;
          .el-input__inner {
            color: #515a6e;
            border: none;
            text-align: left;
            height: 29px;
            line-height: 29px;
            background-color: #fff;
          }
        }
        .start::before {
          content: '';
          display: inline-block;
          vertical-align: middle;
          margin-right: 10px;
          width: 8px;
          height: 8px;
          background: #15ad31;
          border-radius: 50%;
        }
        .unStart::before {
          content: '';
          display: inline-block;
          vertical-align: middle;
          margin-right: 10px;
          width: 8px;
          height: 8px;
          background: #ff9810;
          border-radius: 50%;
        }
      }
    }

    ::v-deep .el-table__fixed-right {
      background-color: #fff;
      font-size: 14px;
      height: 100% !important; //设置高优先，以覆盖内联样式
    }
    ::v-deep .el-table__fixed {
      background-color: #fff;
      font-size: 14px;
      height: 100% !important; //设置高优先，以覆盖内联样式
    }
    ::v-deep .el-table__header {
      th:not(.is-hidden):nth-child(n-2) {
        color: #515a6e;
        background: #f8f8f9;
        .cell {
          padding-bottom: 1px;
        }
      }
      /*     th:not(.is-hidden):last-child::after {
        content: '';
        position: absolute;
        width: 100%;
        height: 1px;
        color: #DCDEE2;
        background: #DCDEE2;
        left: 0;
        bottom: 6px;
      } */
    }
    .circle {
      display: inline-block;
      width: 6px;
      height: 6px;
      border-radius: 50%;
      margin-right: 8px;
      margin-bottom: 0.2vh;
    }
    .defaultCursor:hover {
      cursor: text;
    }

    .downloadUrl {
      cursor: pointer !important;
      color: #2d8cf0;
    }
    .operations {
      cursor: pointer;
      span {
        position: relative;
        display: inline-block;
        padding: 0 8.5px;
        // width: 126px;
        // height: 20px;
        color: #2d8cf0;
      }
      .pullMore {
        vertical-align: middle;
        display: inline-block;
        text-align: center;
        width: 16px;
        height: 16px;
        background: #e7f1ff;
        border-radius: 50%;
        .text {
          display: inline-block;
          padding: 0;
          transform: translateY(-30%);
        }
      }
      .pullMoreCheck {
        background: #e3eefd;
      }
      .selectData {
        border-radius: 4px;
        z-index: 9999999;
        box-shadow: 0 2px 12px 0 rgb(0 0 0 / 10%);
        border: 1px solid #e4e7ed;
        position: absolute;
        width: 70px;
        right: 0;
        .selectUl {
          .selectLi {
            padding: 1px 0;
            font-size: 13px;
            border-radius: 4px;
            color: #2d8cf0;
            background: #fff;
            &:hover {
              background: #f8f8f9;
            }
          }
        }
        .selectUl:first-child {
          .selectLi {
            padding-top: 6px;
          }
        }
        .selectUl:last-child {
          .selectLi {
            padding-bottom: 6px;
          }
        }
      }
      .editRow::after {
        content: '';
        position: absolute;
        right: 0px;
        top: 4px;
        width: 1px;
        height: 14px;
        background: #e9e9e9;
      }
      /*     .deleteRow::before {
        content: '';
        position: absolute;
        left: 0px;
        top: 4px;
        width: 1px;
        height: 14px;
        background: #e9e9e9;
      } */
    }
  }
  @import url('@styles/element-ui.scss');
}
::v-deep .el-input__inner {
  color: #515a6e;
  height: 28px;
  line-height: 28px;
}
@include tableClass;

::v-deep.show_empty .el-table__empty-text {
  display: initial;
}
</style>
