import { BaseService } from '@/api/request';
const api = {};
// NaN undefined null过滤处理
// 注意，尽量不要过滤空字符串
const filterNull = json => {
  let newJson = {};
  let reg = RegExp(/NaN|undefined|null/);
  for (let li in json) {
    if (json[li] && !reg.test(json[li])) {
      newJson[li] = json[li];
    }
  }
  return newJson;
};

/**
 * @description: 获取任务列表的数据
 */
api.getTaskData = function (params = {}) {
  return BaseService.getRequest('api/pmf/v1/jobs', filterNull(params));
};
/**
 * @description: 创建任务
 */
api.addTask = function (params = {}) {
  return BaseService.postRequest('api/pmf/v1/jobs', filterNull(params));
};
/**
 * @description: 校验是否可以删除任务
 */
api.validateDeleteTask = function (id) {
  return BaseService.getRequest('api/pmf/v1/jobs/' + id + '/validateDelete');
};
/**
 * @description: 批量更新任务
 */
api.batchUpdateTasks = function (params = {}) {
  return BaseService.putRequest('api/pmf/v1/jobs', filterNull(params));
};
/**
 * @description: 获取单个任务
 */
api.taskListData = function (params = {}, id) {
  return BaseService.getRequest('api/pmf/v1/jobs/' + id, filterNull(params));
};
/**
 * @description: 更新任务
 */
api.updateTaskData = function (params = {}, id) {
  return BaseService.putRequest('api/pmf/v1/jobs/' + id, filterNull(params));
};

/**
 * @description: 删除任务
 */
api.deleteTaskData = function (id) {
  return BaseService.delRequest('api/pmf/v1/jobs/' + id);
};
/**
 * @description: 下载任务模板
 */
api.downloadTaskDemo = function (params = {}) {
  return BaseService.getRequest('api/pmf/v1/jobs/importTemplate', filterNull(params));
};
/**
 * @description: 导入任务
 */
api.leadinTask = function (params = {}) {
  return BaseService.postRequest('api/pmf/v1/jobs/import', filterNull(params));
};
/**
 * @description: 获取服务项合同序号列表
 */
api.serviceSeqData = function (params = {}) {
  return BaseService.getRequest('api/pmf/v1/servings/contractSeqs', filterNull(params));
};
/**
 * @description: 获取服务项父项列表
 */
api.parentServiceSeqData = function (params = {}) {
  return BaseService.getRequest('api/pmf/v1/servings/parents', filterNull(params));
};
export default api;
