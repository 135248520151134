<template>
  <div>
    <el-drawer
      append-to-body
      class="taskDrawer"
      :title="drawerType === 'edit' ? $t('projectManagement.task.editTaskItem') : $t('projectManagement.task.checkTaskItem')"
      :visible.sync="drawerVisible"
      :size="drawerSize"
      :before-close="handleClose"
    >
      <div class="Container" ref="mainContainer">
        <el-form v-show="drawerType === 'edit'" label-width="60px" :model="searchForm" label-position="top">
          <div class="editFormContainer">
            <!-- 合同序号 -->
            <el-form-item class="seqFormItem" :label="$t('projectManagement.contractNo')">
              <el-tooltip v-if="currentProjectSeq.length > 12" :content="currentProjectSeq" class="item" effect="dark" placement="top">
                <el-input v-model="currentProjectSeq" :disabled="true"> </el-input>
              </el-tooltip>
              <el-input v-else v-model="currentProjectSeq" :disabled="true"> </el-input>
            </el-form-item>

            <!-- 服务项 -->
            <el-form-item class="serviceFormItem" :label="$t('projectManagement.serveItem')">
              <el-tooltip v-if="serviceName.length > 12" :content="serviceName" class="item" effect="dark" placement="top">
                <el-input v-model="serviceName" :disabled="true"> </el-input>
              </el-tooltip>

              <el-input v-else v-model="serviceName" :disabled="true"> </el-input>
            </el-form-item>

            <!-- 项目工作项 -->
            <el-form-item class="projectFormItem" :label="$t('projectManagement.proWorkItem')">
              <el-tooltip v-if="currentTaskName.length > 12" :content="currentTaskName" class="item" effect="dark" placement="top">
                <el-input v-model="currentTaskName" :disabled="true"> </el-input>
              </el-tooltip>
              <el-input v-else v-model="currentTaskName" :disabled="true"> </el-input>
            </el-form-item>

            <el-form-item :label="$t('projectManagement.chargeMember')" class="memberItem">
              <memberSelect
                class="mySelect"
                :projectUpdate="true"
                :disabledUpdate="false"
                :memberSelected="searchForm.assignUserIdIn"
                :allUser="taskStaff"
                @changeMemberSelected="changeMemberSelected"
                :placeholder="'请选择负责成员'"
              />

              <!--       <my-select :isLoadMore="false" :multiple="true" :options="taskStaff" v-model="searchForm.assignUserIdIn"></my-select> -->
            </el-form-item>

            <el-form-item :label="$t('projectManagement.taskItem')">
              <my-select
                class="taskItem"
                :isLoadMore="false"
                :multiple="true"
                :reserveKeyword="true"
                :collapseTags="true"
                :options="allTaskOptions"
                v-model="searchForm.idIn"
                :placeholder="'任务项'"
                :defaultOptionLabel="30"
              ></my-select>
            </el-form-item>

            <el-form-item class="task-btn-group">
              <el-button @click="resetForm" class="saveBtn" type="default" size="medium">{{ $t('commonVariables.reset') }}</el-button>
              <el-button @click="searchTaskData" class="saveBtn" size="medium" type="primary">{{ $t('commonVariables.filtrate') }}</el-button>
            </el-form-item>
          </div>
        </el-form>

        <el-form v-show="drawerType === 'check'" ref="searchForm" :model="searchForm" label-position="top">
          <div class="checkFormContainer">
            <el-form-item :label="$t('projectManagement.task.contractCode')" class="seqNewFormItem" label-position="top">
              <el-tooltip v-if="searchForm.contractSeqEq.length > 12" :content="searchForm.contractSeqEq" placement="top-start">
                <el-select v-model="searchForm.contractSeqEq" filterable clearable placeholder="请选择合同序号">
                  <el-option v-for="item in optionGroup.contractSeqOption" :key="item" :label="item" :value="item"> </el-option>
                </el-select>
              </el-tooltip>
              <el-select v-else v-model="searchForm.contractSeqEq" filterable clearable placeholder="请选择合同序号">
                <el-option v-for="item in optionGroup.contractSeqOption" :key="item" :label="item" :value="item"> </el-option>
              </el-select>
            </el-form-item>
            <el-form-item :label="$t('projectManagement.servingFather')" class="serviceFatherItem" label-position="top">
              <my-select
                :options="optionGroup.parentServiceSeqOption"
                v-model="searchForm.servingParentIdEq"
                :placeholder="'请选择服务项（父级）'"
                :defaultOptionLabel="30"
              ></my-select>
            </el-form-item>
            <el-form-item :label="$t('projectManagement.task.seringName')" class="serviceNewItem" label-position="top">
              <my-select
                :options="workServiceOptions"
                v-model="searchForm.servingIdEq"
                :placeholder="'请选择服务项'"
                :defaultOptionLabel="30"
              ></my-select>
            </el-form-item>
            <el-form-item :label="$t('projectManagement.taskItem')" class="taskNewItem" label-position="top">
              <my-select :options="allTaskOptions" v-model="searchForm.idEq" :placeholder="'请选择任务项'" :defaultOptionLabel="30"></my-select>
            </el-form-item>

            <el-form-item :label="$t('projectManagement.task.taskName')" class="projectTaskItem" label-position="top">
              <my-select :options="workTableData" v-model="searchForm.taskIdEq" :placeholder="'项目工作项'" :defaultOptionLabel="30"></my-select>
            </el-form-item>
          </div>

          <div class="bottomCheckContainer">
            <el-form-item :label="$t('projectManagement.chargeMember')" class="memberNewItem">
              <memberSelect
                class="mySelect"
                :projectUpdate="true"
                :disabled="searchForm.nonAssign"
                :memberSelected="searchForm.assignUserIdIn"
                :allUser="taskStaff"
                @changeMemberSelected="changeMemberSelected"
                :placeholder="'请选择负责成员'"
              />
            </el-form-item>
            <el-form-item class="assignBox">
              <el-checkbox v-model="searchForm.nonAssign" @change="changeCheckBox">{{ $t('projectManagement.viewUnsignTask') }}</el-checkbox>
            </el-form-item>
            <div class="task-btn-group">
              <el-button @click="resetForm" class="saveBtn" type="default" size="medium">{{ $t('commonVariables.reset') }}</el-button>
              <el-button @click="searchTaskData" class="saveBtn" size="medium" type="primary">{{ $t('commonVariables.filtrate') }}</el-button>
            </div>
          </div>
        </el-form>

        <div class="btn-box" v-show="drawerType === 'edit'">
          <div class="update-btn-group">
            <el-button @click="addTaskItem" class="firstBtn" size="medium" type="default">新增</el-button>
            <el-button v-if="batchUpdateAuth" @click="batchModifyItems" class="saveBtn" size="medium" type="default">批量修改</el-button>
            <el-button v-if="uploadAuth" @click="uploadTaskItem" size="medium" class="saveBtn" type="primary">上传任务</el-button>
          </div>
        </div>
      </div>
      <div class="table-container">
        <TaskTable
          :chooseInputArr="chooseInputArr"
          :applyComp="'projectManagement.task'"
          :tableLoading.sync="tableLoading"
          :taskTableData="taskTableData"
          :tableHeight="proDrawerHeight"
          @deleteData="deleteRow"
          :drawerType="drawerType"
          @selection="selection"
          @selectionAll="selectionAll"
          :page.sync="searchForm.page"
          :pagesize.sync="searchForm.pageSize"
          @pageChange="pageChange"
          @editServiceItem="editTaskItem"
          :count="count"
          :authGroup="authGroup"
          :readWeightAuth="readWeightAuth"
        ></TaskTable>
      </div>
      <SimpleDialog
        :modal="false"
        class="taskDialog"
        :title="dialogTitle"
        :visible.sync="dialogFormVisible"
        width="585px"
        showConfirm
        showCancel
        @onCancel="taskCancel"
        @onSubmit="onNoticeDialogSubmit"
      >
        <el-form :model="taskForm" ref="taskForm" :rules="taskRules" class="addTaskForm" label-position="left" label-width="100px">
          <el-form-item v-if="dialogTitle !== '批量修改项'" prop="name" :label="$t('projectManagement.taskItemName')">
            <el-input :disabled="!authGroup.update" placeholder="请输入任务项名称" size="mini" v-model="taskForm.name" clearable> </el-input>
          </el-form-item>
          <el-form-item v-else label="项目工作项">
            <el-select
              v-model="taskForm.taskId"
              filterable
              clearable
              style="width: 100%"
              :disabled="dialogTitle === $t('projectManagement.editServiceItem')"
            >
              <el-option v-for="item in workTableData" :key="item.id" :label="item.name" :value="item.id"> </el-option>
            </el-select>
          </el-form-item>
          <el-form-item :label="$t('projectManagement.num')">
            <el-input-number
              :disabled="!authGroup.update"
              placeholder="请输入数量"
              size="mini"
              v-model="taskForm.size"
              clearable
              class="nums"
              controls-position="right"
            >
            </el-input-number>
          </el-form-item>
          <el-form-item class="taskMemberContainer">
            <div class="taskMemberArr">
              <div class="addHeaderContainer">
                <p class="left">{{ $t('projectManagement.chargeMember') }}</p>
                <p class="right" @click="addItem"><i class="el-icon-circle-plus-outline addIcon"></i>{{ $t('projectManagement.addMember') }}</p>
              </div>
              <div class="addContainer">
                <div class="mainContainer" v-for="(val, idx) in taskForm.assigns" :key="idx" v-show="(val.id && !val.isDeleted) || !val.id">
                  <div class="member">
                    <el-select v-model="taskForm.assigns[idx].userId" filterable placeholder="请选择人员" :clearable="false">
                      <el-option v-for="item in optionGroup.taskOption" :key="item.id" :label="item.name" :value="item.id"> </el-option>
                    </el-select>
                  </div>
                  <div v-if="editWeightAuth" class="authority">
                    <el-input placeholder="请输入权重" size="mini" v-model="taskForm.assigns[idx].weight" clearable> </el-input>
                  </div>
                  <div class="role">
                    <el-select v-model="taskForm.assigns[idx].role" filterable placeholder="请选择角色" :clearable="false">
                      <el-option v-for="item in optionGroup.roleOption" :key="item.id" :label="item.name" :value="item.id"> </el-option>
                    </el-select>
                  </div>
                  <div class="removeOperation">
                    <i class="el-icon-remove-outline removeOp" @click="deleteTaskMember(idx)"></i>
                  </div>
                </div>
              </div>
            </div>
          </el-form-item>
        </el-form>
      </SimpleDialog>
    </el-drawer>
    <!-- <LeadIn
      :routeName="routeName"
      title="批量导入任务项"
      :leadingInVisible.sync="leadingInVisible"
      :confirmId="propTaskId"
      @refreshList="refreshList"
    /> -->
    <LeadInTasks title="批量导入任务项" :leadingInVisible.sync="leadingInVisible" :confirmId="propTaskId" @refreshList="refreshList" />
  </div>
</template>

<script>
import LeadIn from '@/components/LeadIn/index.vue';
import LeadInTasks from '@/components/LeadInTasks/index.vue';
import memberSelect from '@/components/memberSelect/index.vue';
import TaskTable from '@/views/project-management/components/TaskTable';
import taskApi from '@/api/modules/task.api';

export default {
  components: {
    memberSelect,
    LeadIn,
    TaskTable,
    LeadInTasks,
  },

  props: {
    drawerVisible: {
      type: Boolean,
      default: false,
    },
    taskRow: {
      type: Object,
      default: () => {
        return {};
      },
    },
    drawerType: {
      type: String,
      default: '',
    },
    choosedContractItem: {
      type: Object,
      default: () => {
        return {};
      },
    },
    serviceTableData: {
      type: Array,
      default: () => {
        return [];
      },
    },
    workServiceOptions: {
      type: Array,
      default: () => {
        return [];
      },
    },
    workTableData: {
      type: Array,
      default: () => {
        return [];
      },
    },
    taskStaff: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  computed: {
    editWeightAuth() {
      return this.$store.state.app.userConfig.permissionsKeys?.JobManagementEditWeight;
    },
    readWeightAuth() {
      return this.$store.state.app.userConfig.permissionsKeys?.JobManagementReadWeight;
    },
    batchUpdateAuth() {
      return this.$store.state.app.userConfig.permissionsKeys?.JobManagementBulkEdit;
    },
    uploadAuth() {
      return this.$store.state.app.userConfig.permissionsKeys?.JobManagementImport;
    },
    userId() {
      return this.$store.state.user.id;
    },
    routeName() {
      return this.$route.meta.name;
    },
    userName() {
      return this.$store.state.user.name;
    },
    propTaskId() {
      return this.taskRow?.id ?? '';
    },

    elDescriptionLabelStyle() {
      return {
        'text-align': 'center',
        width: '96px',
        'white-space': 'nowrap',
      };
    },

    elDescriptionContentStyle() {
      return {
        'text-align': 'left',
        width: '306px',
        'white-space': 'nowrap',
        'text-overflow': 'ellipsis',
        overflow: 'hidden',
      };
    },
  },
  watch: {
    drawerVisible: {
      deep: true,
      immediate: false,
      handler(newVal) {
        if (newVal) {
          this.currentProjectSeq = this.taskRow.contractSeq ?? '';
          this.searchForm.projectIdEq = this.choosedContractItem.id ?? '';
          this.currentTaskName = this.taskRow.name ?? '';
          this.serviceName = this.taskRow.servingName ?? '';
          this.tableLoading = true;

          if (this.drawerType === 'edit') {
            this.searchForm.taskIdEq = this.taskRow.id;
            const optionForm = {
              projectIdEq: this.searchForm.projectIdEq,
              taskIdEq: this.searchForm.taskIdEq,
            };
            taskApi.getTaskData({ ...optionForm }).then(taskOption => {
              if (taskOption[2].status !== 200) {
                this.$message.error(taskOption[1].message);
              } else {
                this.allTaskOptions = [...taskOption[0].jobs];
              }
            });
            this.chooseInputArr = [
              {
                prop: 'name',
              },
              {
                prop: 'size',
                width: '50px',
                align: 'center',
              },
              {
                prop: 'assigns',
                width: '300px',
              },
            ];
          } else {
            const optionForm = {
              projectIdEq: this.searchForm.projectIdEq,
            };
            taskApi.getTaskData({ ...optionForm }).then(taskOption => {
              if (taskOption[2].status !== 200) {
                this.$message.error(taskOption[1].message);
              } else {
                this.allTaskOptions = [...taskOption[0].jobs];
              }
            });
            this.chooseInputArr = [
              {
                prop: 'contractCode',
                width: '60px',
              },
              {
                prop: 'seringParentName',
              },

              {
                prop: 'seringName',
              },
              {
                prop: 'taskName',
              },
              {
                prop: 'name',
              },
              {
                prop: 'size',
                width: '70px',
                align: 'center',
              },
              {
                prop: 'assigns',
                width: '300px',
              },
            ];
          }
          this.drawerSize = '92%';
          if (!this.authGroup.read) {
            return;
          }

          taskApi.getTaskData({ ...this.searchForm }).then(async res => {
            if (res[2].status !== 200) {
              this.$message.error(res[1].message);
              this.taskTableData = [];
              this.tableLoading = false;
              return;
            }
            this.taskTableData = [...res[0].jobs];
            this.count = res[0].count;
            this.tableLoading = false;
            if (this.firstCreate) {
              this.resizeObserverSize();

              window.removeEventListener('resize', this.resizeObserverSize);
            }
            await taskApi.parentServiceSeqData({ projectId: this.choosedContractItem.id }).then(res => {
              if (res[2].status !== 200) {
                return;
              }
              this.optionGroup.parentServiceSeqOption = [...res[0].servings];
            });
            await taskApi.serviceSeqData({ projectId: this.choosedContractItem.id }).then(res => {
              if (res[2].status !== 200) {
                return;
              }
              this.optionGroup.contractSeqOption = [...res[0].seqs];
            });
            if (!this.authGroup.update) {
              this.optionGroup.taskOption = [
                {
                  id: this.userId,
                  name: this.userName,
                },
              ];
            } else {
              this.optionGroup.taskOption = [...this.taskStaff];
            }
            this.firstCreate = false;
          });
        }
      },
    },
  },
  activated() {
    this.$nextTick(() => {
      !this.firstCreate ? this.resizeObserverSize() : null;
    });
    window.addEventListener('resize', this.resizeObserverSize);
  },
  deactivated() {
    window.removeEventListener('resize', this.resizeObserverSize);
  },
  created() {
    this.authGroup.read = this.$store.state.app.userConfig.permissionsKeys?.JobManagementRead;
    this.authGroup.create = this.$store.state.app.userConfig.permissionsKeys?.JobManagementCreate;
    this.authGroup.update = this.$store.state.app.userConfig.permissionsKeys?.JobManagementUpdate;
    this.authGroup.delete = this.$store.state.app.userConfig.permissionsKeys?.JobManagementDelete;
  },
  data() {
    return {
      allTaskOptions: [],
      leadingInVisible: false,
      optionGroup: {
        parentServiceSeqOption: [],
        contractSeqOption: [],
        taskOption: [],
        roleOption: [
          {
            id: 'PROD',
            name: 'PROD',
          },
          {
            id: 'VALID',
            name: 'VALID',
          },
          {
            id: 'REV',
            name: 'REV',
          },
          {
            id: 'OTHER',
            name: '其它',
          },
        ],
      },

      dialogTitle: '',
      dialogFormVisible: false,
      taskForm: {
        taskId: '',
        projectId: '',
        size: null,
        remark: '',
        assigns: [],
        name: '',
      },
      taskRules: {
        name: [{ required: true, message: '请输入任务项名称', trigger: 'blur' }],
      },
      // 添加任务
      firstCreate: true,
      count: 0,
      proDrawerHeight: 0,
      tableLoading: false,
      showEmpty: false,
      authGroup: {
        read: false,
        create: false,
        delete: false,
        update: false,
      },
      chooseInputArr: [],
      showMemberSelectedName: [],
      searchForm: {
        page: 1,
        pageSize: 20,
        idIn: [],
        taskIdEq: '',
        servingIdEq: '',
        taskIdIn: [],
        projectIdEq: '',
        assignUserIdIn: [],
        servingParentIdEq: '',
        nameCont: '',
        contractSeqEq: '',
        nonAssign: false,
      },
      taskTableData: [],
      drawerSize: '70%',
      // 编辑任务项
      currentSelectionIds: [],
      currentProjectSeq: '',
      currentTaskName: '',
      serviceName: '',
    };
  },

  methods: {
    changeCheckBox(item) {
      if (item) {
        this.searchForm.assignUserIdIn = [];
        this.showMemberSelectedName = [];
      }
    },
    uploadTaskItem() {
      this.leadingInVisible = true;
    },
    refreshList() {
      this.refreshData();
      this.$emit('refreshList');
    },
    selection(selectAssigns) {
      this.currentSelectionIds = selectAssigns.map(item => item.id);
    },
    selectionAll(selectAssigns) {
      this.currentSelectionIds = selectAssigns.map(item => item.id);
    },
    taskCancel() {
      this.dialogFormVisible = false;
      this.taskForm = { taskId: '', projectId: '', size: null, remark: '', assigns: [], name: '' };
      this.$refs.taskForm.clearValidate();
    },
    onNoticeDialogSubmit() {
      this.$refs.taskForm.validate(async valid => {
        if (!valid) {
          return;
        }
        if (!this.taskForm.assigns.length) {
          this.submitFinData();
          return;
        }
        let sum = 0;
        //检查用户是否没有全填占比
        let checkWeightType = false;
        // 触发名字必填检查
        let nameRequired = false;
        // 检查已填写权重的情况下是否有空白项未填写
        let weightRequired = false;

        this.taskForm.assigns.forEach((val, idx) => {
          if (val.weight || val.weight == 0) {
            this.taskForm.assigns[idx].weight = Number(val.weight);
            checkWeightType = true;
            sum += this.taskForm.assigns[idx].weight;
          } else {
            checkWeightType ? (weightRequired = true) : null;
          }

          if (!val.userId) {
            nameRequired = true;
          }
        });
        if (nameRequired) {
          this.$message.error('存在成员未填写');
          return;
        }
        if (weightRequired) {
          this.$message.error('存在权重未填写');
          return;
        }
        if (checkWeightType && sum !== 1) {
          this.$message.error('设置权重总和不等于1，请修改');
          return;
        }
        if (!checkWeightType) {
          const assignLen = this.taskForm.assigns.length;
          let floor = 1 / assignLen;
          floor = Number(floor.toFixed(2));
          if (floor * assignLen === 1) {
            this.taskForm.assigns.forEach((val, idx) => {
              this.taskForm.assigns[idx].weight = floor;
            });
          } else {
            const cal = 1 - floor * assignLen;
            this.taskForm.assigns.forEach((val, idx) => {
              this.taskForm.assigns[idx].weight = floor;
            });

            if (cal > 0) {
              this.taskForm.assigns[0].weight = Number((this.taskForm.assigns[0].weight + Number(Math.abs(cal))).toFixed(2));
            } else {
              this.taskForm.assigns[0].weight = Number((this.taskForm.assigns[0].weight - Number(Math.abs(cal))).toFixed(2));
            }
          }
        }
        this.submitFinData();
      });
    },
    submitFinData() {
      this.taskForm.projectId = this.choosedContractItem.id;

      if (this.dialogTitle === '批量修改项') {
        this.taskForm.ids = [...this.currentSelectionIds];
        taskApi.batchUpdateTasks({ ...this.taskForm }).then(res => {
          if (res[2].status !== 200) {
            this.$message.error(res[1].message);
            return;
          }
          this.taskCancel();
          this.$message.success('保存成功');
          this.refreshData();
        });
        return;
      }

      this.taskForm.taskId = this.propTaskId;
      if (this.dialogTitle === this.$t('projectManagement.task.editTaskItem')) {
        taskApi.updateTaskData({ ...this.taskForm }, this.taskForm.id).then(res => {
          if (res[2].status !== 200) {
            this.$message.error(res[1].message);
            return;
          }
          this.taskCancel();
          this.$message.success('保存成功');
          this.refreshData();
        });
      } else {
        taskApi.addTask({ ...this.taskForm }).then(res => {
          if (res[2].status !== 200) {
            this.$message.error(res[1].message);
            return;
          }
          this.taskCancel();
          this.$message.success('保存成功');
          this.refreshData();
        });
      }
    },
    addItem() {
      this.taskForm.assigns.push({
        userId: '',
        role: '',
        weight: undefined,
      });
    },
    deleteTaskMember(idx) {
      if (this.taskForm.assigns[idx].id) {
        this.taskForm.assigns[idx].isDeleted = true;
      } else {
        this.taskForm.assigns.splice(idx, 1);
      }
    },
    resizeObserverSize() {
      const topBoxHeight = (this.$refs.mainContainer?.offsetHeight ?? 0) + 64;
      const topHeight = topBoxHeight ? topBoxHeight : 0;
      this.otherDomHeight = topHeight + 30;
      this.proDrawerHeight = window.innerHeight - this.otherDomHeight - 64;
    },
    deleteRow(id) {
      taskApi.deleteTaskData(id).then(res => {
        if (res[2].status && res[2].status === 200) {
          this.$message({
            type: 'success',
            message: '已删除',
          });
        }
        this.refreshData();
      });
    },
    editTaskItem(row) {
      this.taskForm = Object.assign({}, row);
      this.dialogFormVisible = true;
      this.dialogTitle = this.$t('projectManagement.task.editTaskItem');
    },
    addTaskItem() {
      this.dialogFormVisible = true;
      this.dialogTitle = '新增任务项';
    },
    batchModifyItems() {
      if (!this.currentSelectionIds.length) {
        this.$message.error('必须先选择一项');
        return;
      }
      this.dialogTitle = '批量修改项';
      this.dialogFormVisible = true;
    },

    pageChange(type, vals) {
      if (type === 'size') {
        this.searchForm.pageSize = vals;
      } else {
        this.searchForm.page = vals;
      }
      this.refreshData();
    },
    refreshData() {
      if (!this.authGroup.read) {
        return;
      }
      this.tableLoading = true;
      const submitForm = JSON.parse(JSON.stringify(this.searchForm));
      submitForm.nonAssign ? (submitForm.nonAssign = 'true') : (submitForm.nonAssign = 'false');
      taskApi.getTaskData({ ...submitForm }).then(res => {
        if (res[2].status !== 200) {
          this.$message.error(res[1].message);
          this.taskTableData = [];
          this.tableLoading = false;
          return;
        }

        this.taskTableData = [...res[0].jobs];
        this.count = res[0].count;
        this.tableLoading = false;
      });
    },

    changeMemberSelected(vals, ids) {
      this.showMemberSelectedName = [...vals];
      this.searchForm.assignUserIdIn = [...ids];
    },

    resetForm() {
      this.searchForm = {
        page: 1,
        pageSize: 20,
        idIn: [],
        taskIdEq: '',
        servingIdEq: '',
        taskIdIn: [],
        projectIdEq: '',
        assignUserIdIn: [],
        servingParentIdEq: '',
        nameCont: '',
        contractSeqEq: '',
        nonAssign: false,
      };
      this.showMemberSelectedName = [];
      this.searchForm.taskIdEq = this.propTaskId;
    },
    searchTaskData() {
      this.refreshData();
    },
    handleClose() {
      this.resetForm();
      this.searchForm.taskIdEq = '';
      this.searchForm.servingIdEq = '';
      this.taskTableData = [];
      this.$emit('update:drawerVisible', false);
    },
  },
};
</script>

<style lang="scss" scoped>
.gl_dialog ::v-deep.el-dialog {
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
}

::v-deep .el-icon-arrow-up {
  line-height: 32px;
}

::v-deep .el-dialog__header {
  justify-content: center;
}

.taskDrawer {
  ::v-deep .el-input__inner {
    height: 32px !important;
  }

  ::v-deep .el-drawer__header {
    padding-left: 32px;
    border-bottom: 1px solid #e8eaec;
    margin-bottom: 0;
    padding-bottom: 18px;
    span {
      font-size: 19px;
      font-weight: 700;
      color: #19233b;
    }
  }

  .Container {
    padding: 24px 32px;
    padding-bottom: 0;

    ::v-deep .el-form-item__label {
      color: #515a6e;
      font-weight: normal;
      font-size: 14px;
      height: 20px;
      padding: 0;
      line-height: 20px;
      text-align: left;
      flex: auto;
    }

    ::v-deep .el-input__inner {
      width: 240px;
    }

    .checkFormContainer {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      flex-wrap: nowrap;
      font-size: 14px;

      .el-form-item {
        margin: 0;
        align-items: center;
        padding: 0;
        flex: 1;
        flex-basis: 20%;
      }

      @media only screen and (max-width: 1366px) {
        ::v-deep .el-input__inner {
          width: 200px;
        }
      }
    }

    .bottomCheckContainer {
      display: flex;
      justify-content: flex-start;
      margin-top: 10px;
      align-items: center;
      .memberNewItem {
        ::v-deep .el-form-item__label {
          margin-bottom: 5px;
        }
        .mySelect {
          width: 240px;
        }
      }
      .assignBox {
        padding-top: 25px;
        padding-left: 15px;
      }
      .task-btn-group {
        display: flex;
        align-items: center;
        height: 36px;
        font-size: 14px;
        padding-left: 60px;
        .saveBtn {
          line-height: 10px;
          height: 32px;
          margin-left: 16px;
        }
      }
    }

    .editFormContainer {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      flex-wrap: nowrap;
      font-size: 14px;

      .el-form-item {
        margin: 0;
        align-items: center;
        padding: 0;
        flex: 1;
        flex-basis: 15%;
      }

      @media only screen and (max-width: 1366px) {
        ::v-deep .el-input__inner {
          width: 180px !important;
        }
        .memberItem {
          width: 180px !important;
        }
      }

      .memberItem {
        .mySelect {
          width: 240px;
        }
        ::v-deep .el-form-item__label {
          margin-bottom: 5px !important;
        }
      }

      .task-btn-group {
        margin-left: 20px;
        display: flex;
        align-items: center;
        height: 36px;
        font-size: 14px;
        margin-left: auto;
        padding-top: 20px;

        .saveBtn {
          line-height: 10px;
          height: 32px;
          margin-left: 10px;
        }
      }
    }

    .btn-box {
      padding-top: 12px;
      // display: flex;
      .update-btn-group {
        .saveBtn {
          line-height: 10px;
          height: 32px;
          margin-left: 16px;
        }
      }
      .firstBtn {
        margin-left: 0;
      }
    }
  }
  .mainContainer {
    padding: 24px 32px;
    padding-bottom: 0;

    .el-form-item {
      margin: 0;
      // display: flex;
      align-items: center;
      padding: 0;
      flex: 1;

      ::v-deep .el-form-item__content {
        margin-left: 5px !important;
        // z-index: 3;
      }
      ::v-deep .el-form-item__label {
        color: #515a6e;
        font-weight: normal;
        font-size: 14px;
        height: 20px;
        padding: 0;
        line-height: 20px;
        text-align: left;
        width: 60px;
      }

      ::v-deep .el-select {
        width: 100%;
      }
      ::v-deep .el-date-editor--daterange {
        width: 100%;
      }
    }

    // .editContainer {
    //   display: flex;
    //   ::v-deep .el-form-item__content {
    //     margin-left: 10px !important;
    //   }

    //   .info {
    //     ::v-deep.el-descriptions-row {
    //       height: 40px !important;
    //     }
    //     .my-label {
    //       // background: #0affff;
    //     }

    //     .my-content {
    //       background: #fde2e2;
    //     }
    //     // background: #fcfcfc;
    //     // display: flex;
    //     // justify-content: flex-start;
    //     .el-form-item {
    //       // margin-right: -100px;
    //     }
    //     .headerClass {
    //       max-width: 200px;
    //       min-width: 100px;
    //       display: inline-block;
    //       white-space: nowrap;
    //       overflow: hidden;
    //       text-overflow: ellipsis;
    //     }
    //   }
    // }
  }

  // dialog样式
  .addTaskForm {
    margin: 24px 32px;
    .nums {
      width: 168px;
    }
    ::v-deep .el-input-number__increase {
      height: 28px;
      border-bottom: 0;
    }
    .taskMemberContainer {
      margin-top: -15px;
      ::v-deep .el-form-item__content {
        margin-left: 0 !important;
      }
      .taskMemberArr {
        margin: 0;
        .addHeaderContainer {
          display: flex;
          justify-content: space-between;
          align-items: center;
          .left {
            font-weight: 600;
          }
          .right {
            cursor: pointer;
            .addIcon {
              color: #1890ff;
              margin-right: 4px;
              font-size: 22px;
              line-height: 28px;
              vertical-align: middle;
            }
          }
        }
        .addContainer {
          overflow: hidden;
          margin: 0 auto;
          width: 100%;
          height: 260px;
          background: #f8f8f9;
          border-radius: 4px;
          overflow-y: auto;
          .mainContainer {
            padding: 3px 0;
            width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: space-around;
            align-items: center;

            .member,
            .authority,
            .role {
              flex: 1;
              max-width: 144px;
            }

            .removeOp {
              cursor: pointer;
              color: #1890ff;
              font-size: 22px;
              margin-right: 0 auto;
            }
          }
        }
      }
    }
  }
}
</style>
