<template>
  <div>
    <SimpleDialog
      :confirmBtnLoading="loading"
      :title="title"
      confirmBtnText="确定导入"
      :visible.sync="leadingInVisible"
      :showConfirm="true"
      :modalCloseBackground="false"
      :showCancel="true"
      width="550px"
      @onCancel="leadingInCancel"
      @onSubmit="leadingInDialog"
    >
      <el-form :model="leadingInForm" ref="leadingInForm" :rules="leadingInRules" class="leadingIn">
        <el-form-item label="请选择文件" label-width="120px" prop="ruleCheck" class="selectFile">
          <el-checkbox-group v-show="false" v-model="leadingInForm.ruleCheck"></el-checkbox-group>
          <el-upload
            class="upload-excel"
            action="string"
            ref="upload"
            :http-request="httpRequest"
            :before-remove="handleRemove"
            :file-list="leadingInForm.fileList"
          >
            <el-button size="small" type="primary">查找文件</el-button>
            <p class="download" @click="downloadDemo">点击下载</p>
          </el-upload>
        </el-form-item>
      </el-form>
    </SimpleDialog>
    <SimpleDialog
      :visible.sync="resError"
      width="30%"
      height="250"
      confirmBtnText="重新导入"
      title="批量导入结果"
      :showConfirm="true"
      :modalCloseBackground="false"
      :showCancel="true"
      @onCancel="warnTipsCancel"
      @onSubmit="restartLeadingIn"
    >
      <p class="errorAttention">导入失败，共出错{{ errorList.length }}行：</p>
      <el-table :data="errorList" style="width: 100%">
        <el-table-column prop="index" label="出错行" align="center">
          <template slot-scope="scope">
            <span>{{ scope.row.index === 0 ? (scope.row.index = '首行') : scope.row.index }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="desc" label="错误描述" align="left"> </el-table-column>
      </el-table>
    </SimpleDialog>
    <el-dialog title="批量导入失败" :visible.sync="tipVisible" width="30%">
      <el-table :data="leadingTips" class="leadingClassName">
        <el-table-column prop="index" label="行数" width="180"> </el-table-column>
        <el-table-column label="原因" width="180">
          <template slot-scope="scope">
            <p v-for="(item, idx) in scope.row.descs" :key="idx">{{ item }}</p>
          </template>
        </el-table-column>
      </el-table>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleClose">取 消</el-button>
        <el-button type="primary" @click="handleClose">确定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import SimpleDialog from '@/components/common/SimpleDialog.vue';
import projectManagementApi from '@/api/modules/projectManagement.api.js';

export default {
  name: 'LeadInTasks',
  components: { SimpleDialog },
  props: {
    title: {
      type: String,
      default: '',
    },
    confirmId: {
      type: [Number, String],
      default: null,
    },
    leadingInVisible: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    const validatefile = (rule, value, callback) => {
      if (value.length === 0 || !value) {
        callback(new Error('请选择需要上传的文件'));
      } else {
        callback();
      }
    };
    return {
      // 导入
      reLeadConfirm: '',
      loading: false,
      leadingTips: [],
      tipVisible: false,
      forceImport: false,
      // 导入
      leadingInForm: { fileList: [], ruleCheck: [] },
      leadingInRules: {
        ruleCheck: [{ required: true, validator: validatefile, trigger: 'change' }],
      },
      uploadFd: null,
      resError: false,
      errorList: [],
    };
  },
  methods: {
    async reSubmit() {
      if (this.forceImport) {
        this.uploadFd.append('forceImport', true);
        let res = await projectManagementApi.leadingServiceXlsx(this.uploadFd);
        if (!res.status === 200) {
          this.$message.error('上传失败');
          return;
        }
        this.$emit('refreshList', this.confirmId);
        this.leadingInCancel();
        this.$message.success('导入成功');
      }
      this.handleClose();
    },
    handleClose() {
      this.loading = false;
      this.tipVisible = false;
    },
    // 批量导入
    httpRequest(params) {
      this.$nextTick(() => {
        this.leadingInForm.fileList = [];
        this.leadingInForm.ruleCheck = [];
        this.leadingInForm.ruleCheck.push(params.action);
        this.leadingInForm.fileList.push(params.file);
        this.uploadFd = new FormData();
        this.uploadFd.append('file', params.file);
        this.uploadFd.append('taskId', this.confirmId);
      });
    },
    async leadingInDialog() {
      this.$refs.leadingInForm.validate(async valid => {
        if (this.loading) {
          return;
        }
        if (!valid) {
          return;
        }
        this.loading = true;
        this.leadingTips = [];
        let res = null;
        res = await projectManagementApi.leadingServiceXlsx(this.uploadFd);
        if (res[2].status !== 200) {
          this.$message.error(res[1]?.message ? res[1].message : '请求失败，请稍候再试');
          this.loading = false;
          return;
        }
        if (res[1].canForceImport && res[1].errors) {
          this.$emit('update:leadingInVisible', false);
          this.reLeadConfirm = '添加为成员';
          this.leadingTips = [...res[1].errors];
          this.forceImport = true;
          this.tipVisible = true;
          return;
        }
        if (!res[1].canForceImport && res[1].errors.length) {
          this.reLeadConfirm = '确定';
          this.forceImport = false;
          this.leadingTips = [...res[1].errors];
          this.tipVisible = true;
          return;
        }
        this.$emit('refreshList', this.confirmId);
        this.leadingInCancel();
        this.$message.success('导入成功');
      });
    },
    handleRemove() {
      this.uploadFd = null;
      this.leadingInForm.ruleCheck = [];
      this.leadingInForm.fileList = [];
    },
    leadingInCancel() {
      this.leadingInForm.ruleCheck = [];
      this.uploadFd = null;
      this.leadingInForm.fileList = [];
      // this.leadingTips = [];
      this.loading = false;
      this.forceImport = false;
      this.$emit('update:leadingInVisible', false);
    },
    async restartLeadingIn() {
      this.resError = false;
      this.leadingInCancel();
      this.$emit('update:leadingInVisible', true);
    },
    warnTipsCancel() {
      this.resError = false;
      this.leadingInCancel();
    },
    async downloadDemo() {
      const BaseServiceApi = process.env.VUE_APP_SERVICE_BASE_URL;
      window.location.href = `${BaseServiceApi}api/pmf/v1/jobs/importTemplate`;
    },
    formatter(row, column, cellValue) {
      return cellValue ? cellValue.join('，') : null;
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep .el-upload-list {
  height: 30px;
}
::v-deep .el-upload-list__item {
  transition: none !important;
}
::v-deep .el-upload-list__item-thumbnail {
  /* 图片在方框内显示长边 */
  object-fit: scale-down !important;
}
.leadingIn {
  padding: 6px 60px;
  ::v-deep .el-form-item__label {
    font-weight: normal;
    font-size: 16px;
    margin-right: 20px;
  }
  .attention {
    padding: 10px 0;
    padding-bottom: 0;
    color: red;
    font-size: 14px;
  }
  .selectFile {
    padding-top: 10px;
  }
  .download {
    display: inline-block;
    cursor: pointer;
    margin: 0;
    vertical-align: middle;
    font-size: 14px;
    padding-top: 1.5px;
    padding-left: 30px;
    color: #2d8cf0;
  }
}
.leadingClassName {
  width: 500px;
  margin: 0 auto;
  max-height: 580px;
  overflow-y: auto;
}
.errorAttention {
  padding: 10px 20px;
  color: red;
  font-size: 14px;
}
</style>
