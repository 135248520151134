<template>
  <div class="project-management__page">
    <TaskDrawer
      :drawerVisible.sync="drawerVisible"
      :serviceTableData="serviceTableData"
      :workTableData="workTableData"
      :choosedContractItem="choosedContractItem"
      :workServiceOptions="workServiceOptions"
      :taskRow="taskRow"
      :drawerType="drawerType"
      :taskStaff="taskStaff"
      @refreshList="getProject(false)"
    ></TaskDrawer>
    <div class="tree-sub" ref="tree-sub">
      <div class="tree-menu" :style="treeHeight">
        <TreeMenu
          :mutipleCheck="true"
          :treeMenuName="$t('workRecord.project')"
          :searchName="$t('workRecord.findProject')"
          :operationCreate="operationCreate"
          :operationExport="operationExport"
          :projectArr="projectArr"
          isTreeStretch
          :choosedContractItem="choosedContractItem"
          :allUser="allUser"
          @addProject="addProject"
          @getChoosedContractItem="getChoosedContractItem"
          :leaveWarnCheck="updatedTaskData.length"
          warnKeyWord="工作项"
          :projectStatus="projectStatus"
          operationShowStatusSelect
          @transfromStatus="transfromStatus"
          @searchKeyword="searchKeyword"
          remoteSearch
        />
      </div>
    </div>
    <div class="app-box" :style="{ width: tableWidth + 'px !important' }">
      <div class="top-box" ref="headerBtns">
        <div class="header">
          <div class="top-header-tit">
            <p class="tit-container">{{ $t('projectManagement.projectHoursTable') }}</p>
            <div class="box">
              <div class="circle"></div>
              <p class="currentRord">{{ choosedContractItem.name }}</p>
            </div>
            <div class="contractDialog">
              <GLIcon icon="icon-hetongmingcheng" :extraStyle="true" class="exportIcon" />
              <p @click="addContractName" class="checkContractName">合同名称</p>
            </div>
            <div v-if="isShowEQ" class="contractDialog">
              <GLIcon icon="icon-yugushuliang" :extraStyle="true" class="exportIcon" />
              <p @click="showEQDialog" class="checkContractName">预估数量</p>
            </div>
          </div>
          <div class="right-container">
            <div @click="toggleExpand" class="tit-container expandControl">
              <span>{{ expanded ? $t('commonVariables.fold') : $t('commonVariables.unfold') }}</span
              ><i :class="expanded ? 'el-icon-arrow-up' : 'el-icon-arrow-down'"></i>
            </div>
            <div v-if="projectArr.length && ((operation && operationUpdate) || (!operation && projectUpdate) || isEditPM)" class="header-btns">
              <el-button @click="cancelEdit" size="medium">{{ $t('commonVariables.reset') }}</el-button>
              <el-button @click="saveEdit('ruleForm')" class="savebtn" size="medium" type="primary">{{ $t('projectManagement.save') }}</el-button>
            </div>
          </div>
        </div>
        <!-- top -->
        <TransitionCollapse @expandOver="expandOver" @retractOver="expandOver" v-show="expanded" :expandHeight="expandHeight">
          <el-form class="edit_from" :model="ruleForm" ref="ruleForm" :rules="rules" label-position="top" label-width="180px">
            <el-form-item :label="$t('projectManagement.projectNo')">
              <el-input v-model="ruleForm.code" :disabled="true" class="projInput"></el-input>
            </el-form-item>
            <el-form-item prop="projectName" :label="$t('projectManagement.projectName')">
              <el-input v-model="ruleForm.projectName" clearable placeholder="请输入" :disabled="disabledUpdate" class="projInput"></el-input>
            </el-form-item>
            <el-form-item :label="$t('projectManagement.projectDirector')">
              <el-select size="mini" v-model="ruleForm.director" filterable clearable disabled class="projInput">
                <el-option v-for="item in allUser" :key="item.id" :label="item.name" :value="item.id"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item :label="$t('projectManagement.projectAD')">
              <el-select size="mini" v-model="ruleForm.ad" filterable clearable disabled class="projInput">
                <el-option v-for="item in allUser" :key="item.id" :label="item.name" :value="item.id"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item prop="directorSelected" :label="$t('projectManagement.projectBO')">
              <el-select
                size="mini"
                v-model="ruleForm.directorSelected"
                filterable
                clearable
                :disabled="disabledUpdate || routeName === '项目管理'"
                class="projInput"
              >
                <el-option v-for="item in allUser" :key="item.id" :label="item.name" :value="item.id"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item prop="chiefPMSelected" :label="$t('projectManagement.projectLEAD')">
              <el-select size="mini" v-model="ruleForm.chiefPMSelected" filterable clearable :disabled="!isEditPM" class="projInput">
                <el-option v-for="item in allUser" :key="item.id" :label="item.name" :value="item.id"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item :label="$t('projectManagement.Member')">
              <memberSelect
                class="projInput"
                :projectUpdate="projectUpdate"
                :disabledUpdate="disabledUpdate"
                :memberSelected="memberSelected"
                :allUser="allUser"
                @changeMemberSelected="changeMemberSelected"
              />
            </el-form-item>

            <el-form-item :label="$t('projectManagement.TimeFrame')">
              <el-date-picker
                v-model="dateVal"
                class="el-date-picker projInput"
                type="daterange"
                range-separator="至"
                :unlink-panels="true"
                :start-placeholder="startPlaceholder"
                :end-placeholder="endPlaceholder"
                :disabled="disabledUpdate"
              ></el-date-picker>
            </el-form-item>
            <el-form-item v-if="routeName === '项目管理' && projectUpdate" prop="type" :label="$t('projectManagement.projectType')">
              <el-select :disabled="disabledUpdate" size="mini" v-model="ruleForm.type" filterable clearable class="projInput">
                <el-option v-for="item in projectType" :key="item" :label="item" :value="item"></el-option>
              </el-select>
            </el-form-item>
            <!--             <el-form-item prop="category" v-if="routeName === '运营管理'" :label="$t('projectManagement.executiveDepartment')">
              <el-select size="mini" v-model="ruleForm.category" filterable clearable class="projInput">
                <el-option v-for="item in categoryType" :key="item" :label="item" :value="item"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item prop="sow" v-if="routeName === '运营管理'" :label="$t('projectManagement.sow')">
              <el-input placeholder="请输入" size="mini" v-model="ruleForm.sow" clearable class="projInput"> </el-input>
            </el-form-item> -->
          </el-form>
        </TransitionCollapse>
      </div>
      <div class="heat-shield"></div>
      <div class="bottom-box" ref="bottom-box">
        <div class="header" ref="header">
          <el-radio-group @input="changeModuleType" v-model="typeHeaderModule" class="checkHeaderBtn" v-if="!operation">
            <el-radio-button :label="$t('projectManagement.editServiceItem')"></el-radio-button>
            <el-radio-button :label="$t('projectManagement.editWorkItem')"></el-radio-button>
          </el-radio-group>
          <span class="checkHeaderTit" @click="checkTaskDrawer" v-if="isWorkItem">{{ $t('projectManagement.task.checkTaskItem') }}</span>
          <div v-if="projectArr.length && ((!operation && projectUpdate) || (operation && operationUpdate))" class="header-btns">
            <el-button v-if="rowAddServiceItem" @click="addServiceItem" class="createNewbtn" size="medium">{{
              $t('projectManagement.createNewServiceItem')
            }}</el-button>

            <el-button v-if="operation && operationUpdate" @click="saveAllServiceItems" class="savebtn" size="medium" type="primary">{{
              $t('projectManagement.save')
            }}</el-button>
            <div class="workItemBtn" v-if="!operation && projectUpdate && typeHeaderModule === $t('projectManagement.editWorkItem')">
              <el-button v-if="this.workCreate" @click="addWorkItem" class="savebtn" size="medium" type="default">
                {{ $t('projectManagement.addWorkItem') }}
              </el-button>
              <el-button @click="dropWorkItem" class="savebtn" size="medium" type="default">
                {{ $t('commonVariables.reset') }}
              </el-button>
              <el-button v-if="isWorkItem" @click="matchTaskList" class="matchbtn" size="medium">{{ $t('projectManagement.matchList') }} </el-button>
              <el-button @click="saveAllServiceItems" class="savebtn" size="medium" type="primary">
                {{ $t('projectManagement.save') }}
              </el-button>
            </div>
          </div>
        </div>

        <EditServiceItemTable
          :matchArr="matchArr"
          :tableHeight="tableHeight"
          :tableLoading.sync="tableLoading"
          :originTableData="tableData"
          :serviceTableData="serviceTableData"
          :resourceOptions="resourceOptions"
          :typeHeaderModule="typeHeaderModule"
          @editShowDialog="editShowDialog"
          :tableOperations="tableOperations"
          @editTaskDrawer="editTaskDrawer"
          @deleteRow="deleteRow"
          @transformTaskData="transformTaskData"
          :checkFirstGetWork="checkFirstGetWork"
          @createProjectWorkItem="createProjectWorkItem"
          @deleteWorkItem="deleteWorkItem"
        />
      </div>

      <SimpleDialog
        class="serviceEdit"
        :title="dialogTitle"
        :visible.sync="dialogFormVisible"
        width="565px"
        showConfirm
        showCancel
        @onCancel="serviceCancel"
        @onSubmit="onNoticeDialogSubmit"
      >
        <el-form :model="serviceForm" ref="serviceForm" :rules="serviceRules" class="serviceForm" label-position="right" label-width="100px">
          <el-form-item prop="name" :label="$t('projectManagement.serveName')">
            <el-select
              v-if="routeName === '项目管理'"
              v-model="serviceForm.name"
              filterable
              clearable
              :disabled="serviceDialogStatus === 'edit' && serviceForm.disabledEdit"
              style="width: 100%"
            >
              <el-option v-for="item in serviceNameOptions" :key="item" :label="item" :value="item"> </el-option>
            </el-select>
            <el-input v-else placeholder="请输入服务项名称" size="mini" v-model="serviceForm.name" clearable> </el-input>
          </el-form-item>
          <!--           <el-form-item v-if="routeName === '运营管理'" prop="parentServiceId" label="上级服务项">
            <el-select v-model="parentServiceId" filterable clearable style="width: 100%" disabled>
              <el-option v-for="item in serviceOptions" :key="item.id" :label="item.name" :value="item.id"> </el-option>
            </el-select>
          </el-form-item> -->

          <el-form-item :label="$t('projectManagement.projectCode')">
            <el-input
              size="mini"
              v-model="serviceForm.projectCode"
              clearable
              :disabled="routeName === '项目管理'"
              :class="{ 'is-edit-disabled': serviceDialogStatus === 'edit' }"
            >
            </el-input>
          </el-form-item>
          <el-form-item :label="$t('projectManagement.projectSeq')">
            <el-input
              size="mini"
              v-model="serviceForm.projectSeq"
              clearable
              :disabled="routeName === '项目管理'"
              :class="{ 'is-edit-disabled': serviceDialogStatus === 'edit' }"
            >
            </el-input>
          </el-form-item>
          <el-form-item :label="$t('projectManagement.contractSize')" class="specialItem">
            <el-input
              placeholder="请输入合同数量"
              size="mini"
              v-model="serviceForm.contractSize"
              clearable
              :disabled="serviceDialogStatus === 'edit' && serviceForm.disabledEdit"
            >
            </el-input>
          </el-form-item>
          <el-form-item :label="$t('projectManagement.contractUnit')" class="specialItem">
            <el-input
              placeholder="请输入合同单位"
              size="mini"
              v-model="serviceForm.contractUnit"
              clearable
              :disabled="serviceDialogStatus === 'edit' && serviceForm.disabledEdit"
            >
            </el-input>
          </el-form-item>
          <!--          <el-form-item v-if="routeName === '运营管理'" :label="$t('projectManagement.remark')" class="remarkItem">
            <el-input type="textarea" :rows="3" placeholder="请输入备注" size="mini" v-model="serviceForm.remark" clearable> </el-input>
          </el-form-item>
          <el-form-item label-width="100px" v-if="routeName === '运营管理'" label="项目编辑" class="remarkItem">
            <el-radio-group size="mini" v-model="serviceForm.disabledEdit">
              <el-radio-button :label="true">禁止</el-radio-button>
              <el-radio-button :label="false">开放</el-radio-button>
            </el-radio-group>
            <span class="remarkTips">禁止则项目组无法修改此服务项信息</span>
          </el-form-item> -->
        </el-form>
      </SimpleDialog>

      <SimpleDialog
        class="serviceEdit"
        title="编辑服务项明细"
        :visible.sync="dialogDetailFormVisible"
        width="535px"
        showConfirm
        showCancel
        @onCancel="DetailCancel"
        @onSubmit="onDetailDialogSubmit"
      >
        <el-form
          :model="detailServiceForm"
          ref="detailServiceForm"
          :rules="detailServiceRules"
          class="serviceForm detailServiceForm"
          label-position="right"
          label-width="100px"
        >
          <el-form-item :label="$t('projectManagement.serveName')">
            <span>{{ detailServiceForm.name }}</span>
          </el-form-item>
          <el-form-item :label="$t('projectManagement.projectCode')">
            <span> {{ detailServiceForm.projectCode }}</span>
          </el-form-item>
          <el-form-item :label="$t('projectManagement.projectSeq')">
            <span> {{ detailServiceForm.projectSeq }}</span>
          </el-form-item>

          <el-form-item prop="tasks" class="tasks" label="服务项明细">
            <div class="addServiceItem">
              <div v-show="isDeleteArr.length < 5">
                <span class="addDetail" @click="addServiceDetail"></span> <span class="textDes">添加服务性明细</span>
              </div>
            </div>
            <div class="detailFormClass">
              <div class="detailItem" v-for="(item, index) in isDeleteArr" :key="item.id" v-show="!item.isDeleted">
                <span class="idxSlot">{{ indexMethod(index) }}</span>
                <el-select v-model="isDeleteArr[index].itemId" filterable clearable style="width: 100%">
                  <el-option v-for="item in resourceOptions" :key="item.id" :label="item.name" :value="item.id"> </el-option>
                </el-select>
                <span class="deleteDetail" @click="deleteDetail(index)"></span>
              </div>
            </div>
          </el-form-item>
        </el-form>
      </SimpleDialog>

      <SimpleDialog
        class="workCreate"
        :title="$t('projectManagement.createProWorkItem')"
        confirmBtnText="保存"
        :visible.sync="dialogWorkItemVisible"
        width="686px"
        showConfirm
        showCancel
        @onCancel="workDialogCancel"
        @onSubmit="onWorkDialogSubmit"
      >
        <div class="mainContainer">
          <!-- 从编辑任务项的任务项行选择 -->

          <span v-show="workItemDialogType === 'edit'" class="serviceNameTit"> {{ serviceName }}</span>
          <el-form :rules="workRules" :model="workForm" ref="workForm" :inline="true" class="formWorkItem">
            <div v-show="workItemDialogType === 'create'" class="headerContainer">
              <el-form-item>
                <el-select v-model="workForm.seq" @change="resetServiceId" filterable clearable>
                  <el-option v-for="item in projectSeqArr" :key="item" :label="item" :value="item"> </el-option>
                </el-select>
              </el-form-item>
              <el-form-item prop="id" label=" ">
                <my-select :options="fixedWorkServiceOptions" v-model="workForm.id" placeholder="请选择服务项"></my-select>
              </el-form-item>

              <!--               <el-tooltip class="item" effect="light" content="这里是所选的合作数量" placement="top">
                <i class="el-icon-info" style="font-size: 18px"></i>
              </el-tooltip> -->
            </div>
            <el-form-item class="taskMemberContainer">
              <div class="taskMemberArr">
                <div class="addHeaderContainer">
                  <p class="left">{{ $t('projectManagement.workItem') }}</p>
                  <p class="right" @click="addItem"><i class="el-icon-circle-plus-outline addIcon"></i>{{ $t('projectManagement.add') }}</p>
                </div>
                <div class="addContainer">
                  <div class="labelContainer">
                    <p>{{ $t('projectManagement.projectWork') }}</p>
                    <p>{{ $t('projectManagement.standardWork') }}</p>
                    <p>{{ $t('projectManagement.number') }}</p>
                  </div>
                  <div class="addMainContainer" v-show="workForm.taskData.length" v-for="(val, idx) in workForm.taskData" :key="idx">
                    <div class="role">
                      <el-tooltip
                        v-if="workForm.taskData[idx].name.length > 12"
                        class="item"
                        effect="dark"
                        :content="workForm.taskData[idx].name"
                        placement="top-start"
                      >
                        <el-autocomplete
                          value-key="name"
                          class="inline-input"
                          v-model="workForm.taskData[idx].name"
                          :fetch-suggestions="querySearch"
                          placeholder="请输入项目工作项"
                          @select="selectQuery($event, idx)"
                          @change="inputQuery($event, idx)"
                        ></el-autocomplete>
                      </el-tooltip>
                      <el-autocomplete
                        v-else
                        value-key="name"
                        class="inline-input"
                        v-model="workForm.taskData[idx].name"
                        :fetch-suggestions="querySearch"
                        placeholder="请输入项目工作项"
                        @select="selectQuery($event, idx)"
                        @change="inputQuery($event, idx)"
                      ></el-autocomplete>
                    </div>
                    <div class="member">
                      <el-tooltip
                        v-if="getStandardWorkItem(workForm.taskData[idx].itemId).length > 12"
                        class="item"
                        effect="dark"
                        :content="getStandardWorkItem(workForm.taskData[idx].itemId)"
                        placement="top-start"
                      >
                        <el-select v-model="workForm.taskData[idx].itemId" filterable placeholder="请选择标准工作项" :clearable="false">
                          <el-option v-for="item in resourceOptions" :key="item.id" :label="item.name" :value="item.id"> </el-option>
                        </el-select>
                      </el-tooltip>
                      <el-select v-else v-model="workForm.taskData[idx].itemId" filterable placeholder="请选择标准工作项" :clearable="false">
                        <el-option v-for="item in resourceOptions" :key="item.id" :label="item.name" :value="item.id"> </el-option>
                      </el-select>
                    </div>

                    <div class="authority">
                      <el-input placeholder="请输入" size="mini" v-model="workForm.taskData[idx].size" clearable> </el-input>
                    </div>
                    <div class="removeOperation">
                      <i class="el-icon-remove-outline removeOp" @click="deleteTaskMember(idx)"></i>
                    </div>
                  </div>
                </div>
              </div>
            </el-form-item>
          </el-form>
        </div>
      </SimpleDialog>
      <SimpleDialog
        class="checkContractDialog"
        title="编辑合同名称"
        :visible.sync="dialogContractVisible"
        width="814px"
        showConfirm
        showCancel
        @onCancel="contractDialogCancel"
        @onSubmit="contractDialogSubmit"
      >
        <EditRowContainer
          isDeleted
          @emitContractData="emitContractData"
          class="editRowContainer"
          leftHeader="编辑合同名称"
          rightHeader="添加"
          :fillDataArr="fillDataArr"
          :contractNames="contractNames"
          :dialogContractVisible="dialogContractVisible"
        />
      </SimpleDialog>
      <SimpleDialog
        class="checkContractDialog checkEQDialog"
        title="预估数量"
        :visible.sync="dialogEQVisible"
        width="440px"
        showConfirm
        showCancel
        confirmBtnText="保存"
        @onCancel="DialogEQCancel"
        @onSubmit="DialogEQSubmit"
      >
        <el-form :rules="eqRules" class="eqForm" ref="eqForm" :model="eqForm" label-position="left" label-width="160px">
          <el-form-item prop="estimateSubjectsCount" label="预估受试者数量">
            <el-input v-model="eqForm.estimateSubjectsCount" size="mini"></el-input>
          </el-form-item>
          <el-form-item prop="estimateAverageVisitCount" label="预估平均访视数量">
            <el-input v-model="eqForm.estimateAverageVisitCount" size="mini"></el-input>
          </el-form-item>
          <el-form-item prop="estimateDataCleanCount" label="预估数据清理的总次数">
            <el-input v-model="eqForm.estimateDataCleanCount" size="mini"></el-input>
          </el-form-item>
        </el-form>
      </SimpleDialog>
      <el-dialog title="提示" class="saveWorkItemTipsDialog" :visible="warnDialogVisible" :before-close="closeWarnDialog" width="20%">
        <div class="tips">当前存在工作项单位不一致的情况，请参考标准单位填写工作总量，以免影响项目进度计算。</div>
        <div class="tips">如已处理，请忽略。</div>
      </el-dialog>
    </div>
  </div>
</template>
<script>
import TreeMenu from '@/components/TreeMenu/TreeMenu.vue';
import EditRowContainer from '@/components/EditRowContainer/index.vue';
import memberSelect from '@/components/memberSelect/index.vue';
import TransitionCollapse from '@/components/common/TransitionCollapse';

import TaskDrawer from './components/TaskDrawer.vue';
import EditServiceItemTable from './components/EditServiceItemTable.vue';

import projectManagementApi from '@/api/modules/projectManagement.api.js';
import operationManagementApi from '@/api/modules/operationManagement.api.js';
import resourceManagementApi from '@/api/modules/resourceManagement.api';
import scheduleManagementApi from '@/api/modules/scheduleManagement.api.js';
import taskManagementApi from '@/api/modules/task.api';

import { dayFormat } from '@/utils/time-tools';
import removeDuplicate from '@/utils/eliminatingRedundancy';
import { mixinsWorkRecord } from '@/mixins/treeMenu';
import { mixinsProjectStatement } from '@/mixins/authGroup';
export default {
  name: 'ProjectManagement',
  mixins: [mixinsWorkRecord, mixinsProjectStatement],
  components: { TreeMenu, memberSelect, EditServiceItemTable, TaskDrawer, TransitionCollapse, EditRowContainer },
  props: {
    operation: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    const validateEQData = (rule, value, callback) => {
      if (isNaN(Number(value))) {
        callback(new Error('请填写数字'));
      } else if (this.ruleForm.category === 'DM' && !value) {
        callback(new Error('此项在当前条件下必填'));
      } else {
        callback();
      }
    };
    const validServiceItem = (rule, value, callback) => {
      if (this.isDeleteArr.length <= 0 || !this.isDeleteArr) {
        callback(new Error('服务项明细至少填写一项'));
      } else {
        callback();
      }
    };
    const validateProjectName = (rule, value, callback) => {
      if (!value) {
        callback(new Error('请填写项目名称'));
      } else if (this.projectNameError) {
        callback(new Error('名称已被占用'));
      } else {
        callback();
      }
    };
    const validateServiceName = (rule, value, callback) => {
      if (!value) {
        callback(new Error('请填写服务项名称'));
      } else if (this.serviceNameValidateError) {
        callback(new Error('该项目的服务项名称已被占用'));
      } else {
        callback();
      }
    };
    const projectTypeIsRequired = (rule, value, callback) => {
      if (!value && this.isRequired) {
        callback(new Error('请选择项目类型'));
      } else {
        callback();
      }
    };
    return {
      // label,type,valueType,position,optionGroup
      fillDataArr: [
        { label: 'contractCode', type: 'select', position: 0, optionGroup: [] },
        { label: 'contractName', type: 'input', position: 1 },
      ],
      contractNames: [],
      submitContractNames: [],
      lastChoosedItem: null,
      matchArr: [],
      dialogEQVisible: false,
      workForm: { seq: '', id: '', taskData: [] },
      projectSeqArr: [],
      serviceName: {},
      workItemDialogType: '',
      dialogWorkItemVisible: false,
      workDialogTitle: '',
      updatedTaskData: [],
      checkFirstGetWork: false,
      // typeHeaderModule: this.$t('projectManagement.editServiceItem'),
      projectType: [],
      workRules: {
        id: [{ required: true, message: '请选择服务项', trigger: 'change' }],
      },
      tableOperations: [
        {
          label: 'projectCode',
          value: 'projectCode',
          width: 30,
        },
        {
          label: 'projectSeq',
          value: 'projectSeq',
          width: 30,
        },
        {
          label: 'serviceItem',
          value: 'name',
          showTips: true,
          style: 'margin-left: -4px; margin-right: 8px',
          width: 60,
          desc: '右上角有橙色角标服务项为据实结算',
        },
        {
          label: 'contractSize',
          value: 'contractSize',
          showTips: true,
          style: 'margin-left: -4px; margin-right: 8px',
          width: 20,
        },
        {
          label: 'contractUnit',
          value: 'contractUnit',
          showTips: true,
          style: 'margin-left: -4px; margin-right: 8px',
          width: 40,
        },
      ],
      drawerType: '',
      drawerVisible: false,
      // 抽屉
      tableWidth: 0,
      serviceNameValidateError: false,
      projectNameError: false,
      calHeight: 0,
      expanded: true,
      tableHeight: 0,
      expandHeight: 500,
      // 编辑和明细编辑相关数据
      parentServiceId: undefined,
      serviceRowId: '',

      serviceForm: {
        parentId: undefined,
        projectCode: '',
        projectSeq: '',
        contractSize: null,
        contractUnit: '',
        remark: '',
        name: '',
        disabledEdit: null,
      },
      isDeleteArr: [],
      filterArr: [],
      detailServiceForm: { projectCode: '', projectSeq: '', name: '', tasks: [] },
      detailServiceRules: {
        tasks: [{ required: true, validator: validServiceItem, trigger: 'change' }],
      },
      serviceRules: {
        name: [{ required: true, validator: validateServiceName, trigger: 'blur' }],
        parentId: [{ required: true, message: '请选择上级服务项', trigger: 'change' }],
      },
      eqRules: {
        estimateSubjectsCount: [{ validator: validateEQData, trigger: 'blur' }],
        estimateAverageVisitCount: [{ validator: validateEQData, trigger: 'blur' }],
        estimateDataCleanCount: [{ validator: validateEQData, trigger: 'blur' }],
      },
      resourceOptions: [],
      serviceOptions: [],
      serviceNameOptions: [],
      workServiceOptions: [],
      fixedWorkServiceOptions: [],
      editShow: false,
      editDetailShow: false,
      serviceDialogStatus: '',
      dialogFormVisible: false,
      dialogContractVisible: false,
      dialogDetailFormVisible: false,

      projectArr: [], //项目列表
      dateVal: [], //时间范围
      startPlaceholder: '开始月份',
      endPlaceholder: '结束月份',
      choosedContractItem: { id: null },
      allUser: [], //返回的所有供选择的人
      memberSelected: [], //成员
      showMemberSelectedName: [],
      dialogTitle: '',
      //校验表单规则
      ruleForm: {
        type: '',
        sow: '',
        category: '',
        projectName: '',
        directorSelected: '', //负责人
        chiefPMSelected: '', //首席项目管理
        ad: '',
        director: '',
        code: '',
      },
      eqForm: {
        estimateSubjectsCount: null,
        estimateAverageVisitCount: null,
        estimateDataCleanCount: null,
      },
      taskRow: {},
      taskStaff: [],
      rules: {
        code: [{ required: true, message: '请输入项目编号', trigger: 'blur' }],
        projectName: [{ required: true, validator: validateProjectName, trigger: 'blur' }],
        directorSelected: [{ required: true, message: '请选择负责人', trigger: 'change' }],
        chiefPMSelected: [{ required: true, message: '请选择首席项目管理人', trigger: 'change' }],
        type: [{ validator: projectTypeIsRequired, trigger: 'change' }],
        category: [{ required: true, message: '请选择项目分类', trigger: 'change' }],
      },
      serviceTableData: [],
      workTableData: [],
      tableData: [],
      tableLoading: false,
      toggleFlag: false,
      projectStatus: 0,
      warnDialogVisible: false,
    };
  },

  async created() {
    await this.getSearchByUserName();
    await this.getProject();
  },

  computed: {
    isEditPM() {
      return this.$store.state.app.userConfig.permissions.includes('ProjectManagementEditPm');
    },
    typeHeaderModule: {
      get() {
        return this.toggleFlag ? this.$t('projectManagement.editWorkItem') : this.$t('projectManagement.editServiceItem');
      },
      set() {},
    },

    getStandardWorkItem() {
      return id => {
        const standardItem = this.resourceOptions.find(item => {
          return item.id === id;
        });
        if (standardItem) {
          return standardItem.name;
        } else return '';
      };
    },
    isShowEQ() {
      return this.routeName === '项目管理' && (this.ruleForm.category === 'DM' || !this.ruleForm.category);
    },
  },
  methods: {
    contractDialogCancel() {
      this.contractNames = [];
      this.submitContractNames = [];
      this.getProject(false);
      this.dialogContractVisible = false;
    },
    emitContractData(data) {
      this.submitContractNames = [...data];
    },
    async contractDialogSubmit() {
      const res = await projectManagementApi.batchSaveContractNames({ contractNames: this.submitContractNames }, this.choosedContractItem.id);
      if (res[2].status !== 200) {
        this.$message.error(res[1].message);
        return;
      }

      this.$message.success('提交成功');
      this.contractDialogCancel();
    },
    async addContractName() {
      const res = await scheduleManagementApi.getContractNames({ projectId: this.choosedContractItem.id });
      if (res[2].status !== 200) {
        this.$message.error(res[1].message);
        return;
      }

      this.fillDataArr[0].optionGroup = [...res[0].codes];
      this.contractNames = this.choosedContractItem?.contractNames?.length ? JSON.parse(JSON.stringify(this.choosedContractItem.contractNames)) : [];
      this.dialogContractVisible = true;
    },
    showEQDialog() {
      this.dialogEQVisible = true;
    },
    async DialogEQSubmit() {
      this.$refs.eqForm.validate(async valid => {
        if (!valid) {
          return;
        }
        const res = await projectManagementApi.updateEstimatedQuantity(this.choosedContractItem.id, this.eqForm);
        if (res[2].status !== 200) {
          this.$message.error(res[1].message);
          return;
        }
        this.$message.success('提交成功');
        this.DialogEQCancel();
      });
    },
    DialogEQCancel() {
      this.getProject(false);
      this.dialogEQVisible = false;
      this.$refs.eqForm.clearValidate();
    },
    async matchTaskList() {
      const res = await projectManagementApi.getMatchList(this.choosedContractItem.id);
      if (res[2].status !== 200) {
        this.$message.error(res[1].message);
        return;
      }
      this.matchArr = [...res[0].tasks];
    },

    async resetServiceId(val) {
      this.workForm.id = '';
      let [data] = await projectManagementApi.getServiceByProject({
        projectIdEq: this.choosedContractItem.id,
        projectSeqEq: val,
        hasChildren: false,
      });
      this.fixedWorkServiceOptions = [...data.servings];
    },
    selectQuery(choosedItem, idx) {
      this.inputQuery(choosedItem, idx);
    },
    inputQuery(choosedItem, idx) {
      let changeItem = null;
      let choosedName = '';
      if (typeof choosedItem === 'object') {
        changeItem = Object.assign({}, choosedItem);
        choosedName = choosedItem.name;
      } else {
        choosedName = choosedItem;
      }
      if (!choosedName) {
        this.workForm.taskData[idx].itemId = null;
        return;
      }
      if (!changeItem) {
        this.workForm.taskData[idx].itemId = null;
      } else {
        this.workForm.taskData[idx].itemId = changeItem.id ?? null;
      }
    },

    querySearch(queryString, cb) {
      const customerEqs = [...this.resourceOptions];
      const results = queryString ? customerEqs.filter(this.createFilter(queryString)) : customerEqs;
      // 调用 callback 返回建议列表的数据
      cb(results);
    },
    createFilter(queryString) {
      return customerEq => {
        return customerEq.name.toLowerCase().indexOf(queryString.toLowerCase()) === 0;
      };
    },
    addItem() {
      this.workForm.taskData.push({
        // sortIndex:用于获取当前行数据的定位使用
        // sortIndex: this.workForm.taskData.length,
        itemId: null,
        name: '',
        size: null,
      });
    },
    deleteTaskMember(idx) {
      this.workForm.taskData.splice(idx, 1);
      /*      this.workForm.taskData.map((item, newIdx) => {
        this.workForm.taskData[idx].sortIndex = newIdx;
      }); */
    },
    addWorkItem() {
      this.createProjectWorkItem(null, 'create');
    },
    dropWorkItem() {
      this.$confirm('确定要重置吗？重置后未保存的数据将丢失', '注意', {
        confirmButtonText: this.$t('commonVariables.complete'),
        cancelButtonText: this.$t('commonVariables.cancel'),
      }).then(() => {
        this.checkFirstGetWork = false;
        this.getWorkByProject(this.choosedContractItem.id);
      });
    },
    deleteWorkItem(id) {
      projectManagementApi.deleteWorkItem(id).then(res => {
        if (res[2].status !== 200) {
          this.$alert('保存失败！' + res[1].message, '提示', {
            confirmButtonText: '确定',
            type: 'error',
          });
          return;
        }
        this.checkFirstGetWork = false;
        this.getWorkByProject(this.choosedContractItem.id);
        this.$message.success('保存成功！');
      });
    },
    async createProjectWorkItem(row, type) {
      if (type === 'edit') {
        this.serviceName = row.name;
        this.workForm.id = row.id;
      }

      this.dialogWorkItemVisible = true;
      this.workItemDialogType = type;
    },
    onWorkDialogSubmit() {
      this.$refs.workForm.validate(async valid => {
        if (!valid) {
          return;
        }
        projectManagementApi.batchCreateWorkItems({ tasks: [...this.workForm.taskData] }, this.workForm.id).then(res => {
          if (res[2].status !== 200) {
            this.$alert('保存失败！' + res[1].message, '提示', {
              confirmButtonText: '确定',
              type: 'error',
            });
            return;
          }
          this.checkFirstGetWork = false;
          this.getWorkByProject(this.choosedContractItem.id);
          this.$message.success('保存成功！');
          this.workDialogCancel();
        });
      });
    },
    workDialogCancel() {
      this.dialogWorkItemVisible = false;
      this.workForm = { id: '', seq: '', taskData: [] };
      this.fixedWorkServiceOptions = [...this.workServiceOptions];
    },
    async changeModuleType(itemType) {
      const loading = this.$loading({
        lock: true,
        text: '正在加载中，请稍候...',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.5)',
      });
      // 编辑工作项
      if (itemType === this.$t('projectManagement.editWorkItem')) {
        this.toggleFlag = true;
        this.tableData = [];
        this.tableOperations = [
          {
            label: 'task.contractCode',
            value: 'contractSeq',
            showTips: true,
            width: 80,
          },
          {
            label: 'serviceItem',
            value: 'servingName',
            showTips: true,
            width: 200,
            desc: '右上角有橙色角标服务项为据实结算',
          },
          {
            label: 'contractSize',
            value: 'contractSize',
            width: 80,
          },
          {
            label: 'contractUnit',
            value: 'contractUnit',
            width: 80,
          },
          {
            label: 'projectWork',
            value: 'id',
            key: 'name',
            width: 200,
            type: 'input',
            showTips: true,
          },
          {
            label: 'number',
            value: 'size',
            width: 80,
            type: 'inputNumber',
          },
        ];
        if (this.standardServiceRead) {
          this.tableOperations = this.tableOperations.concat([
            {
              label: 'standardWork',
              value: 'itemId',
              width: 240,
              type: 'select',
              showTips: true,
            },
            {
              label: 'standardUnit',
              width: 80,
              // 独立联动项
              type: 'concat',
            },
          ]);
        }
        await this.getWorkByProject(this.choosedContractItem.id);

        loading.close();
      } else {
        this.toggleFlag = false;

        this.tableOperations = [
          {
            label: 'projectCode',
            value: 'projectCode',
            width: 140,
          },
          {
            label: 'projectSeq',
            value: 'projectSeq',
            width: 140,
          },
          {
            label: 'serviceItem',
            value: 'name',
            showTips: true,
            style: 'margin-left: -4px; margin-right: 8px',
            desc: '右上角有橙色角标服务项为据实结算',
          },
          {
            label: 'contractSize',
            value: 'contractSize',
            showTips: true,
            style: 'margin-left: -4px; margin-right: 8px',
          },
          {
            label: 'contractUnit',
            value: 'contractUnit',
            showTips: true,
            style: 'margin-left: -4px; margin-right: 8px',
          },
        ];
        this.lastChoosedItem.id === this.choosedContractItem.id
          ? (this.tableData = [...this.serviceTableData])
          : this.getServiceByProject(this.choosedContractItem.id);
        loading.close();
      }
    },
    //获取该项目服务项的tableList数据
    async getServiceByProject(id) {
      this.tableLoading = true;
      let [data] = await projectManagementApi.getServiceByProject({ projectIdEq: id, hasChildren: false });
      this.serviceTableData = [...data.servings];
      this.tableData = [...data.servings];
      this.serviceOptions = [...data.servings];
      if (this.routeName === '项目管理') {
        let [serviceData] = await projectManagementApi.getServiceNames();
        this.serviceNameOptions = [...serviceData.names];
      }

      this.lastChoosedItem = Object.assign({}, this.choosedContractItem);
      this.tableLoading = false;
    },
    //获取该项目工作项项的tableList数据
    async getWorkByProject(projectId) {
      if (!this.workRead) {
        return;
      }
      if (this.typeHeaderModule === this.$t('projectManagement.editServiceItem')) {
        this.checkFirstGetWork = false;
        return;
      }
      // 这里有进行是否需要刷新数据的判断，在切换项目列表，首次加载以及保存项目数据的时候会变化
      if (this.checkFirstGetWork) {
        this.tableData = [...this.workTableData];
        return;
      }
      this.tableLoading = true;
      const res = await projectManagementApi.getWorkDataByProject({ projectId: projectId });
      if (res[2].status !== 200) {
        this.tableLoading = false;
        this.$message.error(res[2].message);
        return;
      }
      this.workTableData = [...res[0].tasks];
      this.tableData = [...res[0].tasks];
      this.tableLoading = false;
      // 第一次点击后令其为true，防止反复刷新
      this.checkFirstGetWork = true;
    },
    async checkTaskDrawer() {
      this.drawerType = 'check';

      this.drawerVisible = true;
    },
    expandOver() {
      this.calTableDom();
    },
    calTableRangeWidth() {
      this.tableWidth = window.innerWidth - this.$refs['tree-sub'].offsetWidth - 16 - 28 - (this.$store.getters.sidebar.opened ? 208 : 55);
    },
    toggleExpand() {
      this.expanded = !this.expanded;
    },
    calTableDom() {
      this.$nextTick(() => {
        // 上导航(包含内外边距) 路由菜单滑轨(包含内外边距) ref->top-box,header bottom-box的内边距和外边距
        this.otherDomHeight = (this.$refs.headerBtns?.offsetHeight ?? 0) + (this.$refs.header?.offsetHeight ?? 0) + 32 + 50 + 16 + 12 + 5 + 24;
        this.tableHeight = window.innerHeight - this.otherDomHeight;
      });
    },
    // 复制相关合同信息到空白行的同步table操作
    loadTableData(tableData) {
      this.tableData = JSON.parse(JSON.stringify(tableData));
    },
    DetailCancel() {
      this.detailServiceForm = { projectCode: '', projectSeq: '', name: '', tasks: [] };
      this.$refs.detailServiceForm.resetFields();
      this.dialogDetailFormVisible = false;
    },

    async onDetailDialogSubmit() {
      this.$refs['detailServiceForm'].validate(async valid => {
        if (valid) {
          this.filterArr = this.detailServiceForm.tasks.filter(item2 => {
            return !this.isDeleteArr.find(fxs => {
              return fxs.id === item2.id;
            });
          });
          this.filterArr.forEach(i => {
            i.isDeleted = true;
          });
          this.isDeleteArr = this.isDeleteArr.concat(this.filterArr);
          await projectManagementApi.saveServiceDetailItems({ tasks: this.isDeleteArr }, this.serviceRowId);
          this.$message.success('保存成功！');

          this.isDeleteArr = this.isDeleteArr.filter(val => {
            return !val.isDeleted;
          });

          this.getServiceByProject(this.choosedContractItem.id);
          this.DetailCancel();
        }
      });
    },
    indexMethod(index) {
      return index + 1 < 10 ? index + 1 : index + 1;
    },
    serviceCancel() {
      this.serviceForm = {
        parentId: undefined,
        projectCode: '',
        contractSize: null,
        contractUnit: '',
        remark: '',
        projectSeq: '',
        name: '',
        disabledEdit: null,
      };
      this.parentServiceId = undefined;
      this.$refs['serviceForm'].resetFields();
      this.dialogFormVisible = false;
    },
    async onNoticeDialogSubmit() {
      if (isNaN(Number(this.serviceForm.contractSize))) {
        this.$message.error('填写的数量必须是数字类型');
        return;
      }
      if (!this.serviceForm.contractSize) {
        this.serviceForm.contractSize = null;
      }
      if (!this.choosedContractItem.id) {
        this.$message.error('请先选择具体项目后再进行操作');
        return;
      }
      if (this.updatedTaskData.length) {
        this.$confirm(this.$t('projectManagement.confirmToLeave'), this.$t('projectManagement.note'), {
          confirmButtonText: this.$t('commonVariables.complete'),
          cancelButtonText: this.$t('commonVariables.cancel'),
        }).then(async () => {
          const [validateRepeat] = await projectManagementApi.identifyServiceName({
            project: this.choosedContractItem.id,
            id: this.serviceForm.id ? this.serviceForm.id : null,
            name: this.serviceForm.name,
            projectCode: this.serviceForm.projectCode,
          });
          if (!validateRepeat.valid) {
            this.serviceNameValidateError = true;
          }
          this.$refs.serviceForm.validate(async valid => {
            if (!valid) {
              this.serviceNameValidateError = false;
              return;
            }
            this.serviceForm.parentId = this.parentServiceId ? this.parentServiceId : undefined;

            const res = await projectManagementApi.saveServiceItems({ servings: [this.serviceForm] }, this.choosedContractItem.id);
            if (res[2].status !== 200) {
              this.$alert('保存失败！' + res[1].message, '提示', {
                confirmButtonText: '确定',
                type: 'error',
              });
              return;
            }
            await this.getServiceByProject(this.choosedContractItem.id);
            this.serviceCancel();
            this.$message.success(this.$t('commonVariables.submittedSuccessfully'));
          });
        });
      } else {
        const [validateRepeat] = await projectManagementApi.identifyServiceName({
          project: this.choosedContractItem.id,
          id: this.serviceForm.id ? this.serviceForm.id : null,
          name: this.serviceForm.name,
          projectCode: this.serviceForm.projectCode,
        });
        if (!validateRepeat.valid) {
          this.serviceNameValidateError = true;
        }
        this.$refs.serviceForm.validate(async valid => {
          if (!valid) {
            this.serviceNameValidateError = false;
            return;
          }
          this.serviceForm.parentId = this.parentServiceId ? this.parentServiceId : undefined;
          const res = await projectManagementApi.saveServiceItems({ servings: [this.serviceForm] }, this.choosedContractItem.id);
          if (res[2].status !== 200) {
            this.$alert('保存失败！' + res[1].message, '提示', {
              confirmButtonText: '确定',
              type: 'error',
            });
            return;
          }
          await this.getServiceByProject(this.choosedContractItem.id);
          this.serviceCancel();
          this.$message.success(this.$t('commonVariables.submittedSuccessfully'));
        });
      }
    },
    changeMemberSelected(vals, ids) {
      this.showMemberSelectedName = [...vals];
      this.memberSelected = [...ids];
    },
    //按名字检索用户
    async getSearchByUserName() {
      let [data] = await projectManagementApi.getSearchUserName({ page: 1, pagesize: 99999 });
      let [typeData] = await projectManagementApi.getProjectTypeList();
      if (data) this.allUser = data.user;
      if (typeData) this.projectType = typeData.types;
    },

    //获取所有项目列表
    async getProject(getFirstData = true) {
      const loading = this.$loading({
        lock: true,
        text: '正在加载中，请稍候...',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.5)',
      });
      let data = null;
      if (this.operation) {
        operationManagementApi.getOperateProject({ statusEq: this.projectStatus, page: 1, pagesize: 99999 }).then(async res => {
          data = Object.assign({}, res[0]);
          if (!data) {
            loading.close();
            return;
          }
          loading.close();
          this.projectArr = [...data.project];
          resourceManagementApi.getServiceData({ page: 1, pagesize: 99999 }).then(res => {
            this.resourceOptions = [...res[0].serviceItems];
          });

          if (this.projectArr.length) {
            //默认获取第一个合同的数据
            if (getFirstData) {
              this.choosedContractItem = this.projectArr[0];
            }
            this.tableLoading = true;
            await this.getProDetail(this.choosedContractItem.id);
            await this.getServiceByProject(this.choosedContractItem.id);
          }
        });
      } else {
        projectManagementApi
          .getAdminProject({
            statusEq: this.projectStatus,
            page: 1,
            pagesize: 99999,
            keyword: this.projectArrKeyword,
          })
          .then(async res => {
            data = Object.assign({}, res[0]);
            if (!data) {
              loading.close();
              return;
            }
            loading.close();
            this.projectArr = [...data.project];
            if (this.projectArr.length) {
              //默认获取第一个合同的数据
              if (getFirstData) {
                this.choosedContractItem = this.projectArr[0];
              }
              this.contractNames = this.choosedContractItem?.contractNames?.length
                ? JSON.parse(JSON.stringify(this.choosedContractItem.contractNames))
                : [];
              this.tableLoading = true;
              await this.getProDetail(this.choosedContractItem.id);
              if (this.ruleForm.type + '' !== 'null' && this.ruleForm.type !== '') {
                resourceManagementApi.getServiceData({ page: 1, pagesize: 99999, fullCategoryEq: this.ruleForm.type }).then(res => {
                  this.resourceOptions = [...res[0].serviceItems];
                });
              } else {
                this.resourceOptions = [];
              }

              if (this.isWorkItem) {
                await this.getWorkByProject(this.choosedContractItem.id);
              } else {
                await this.getServiceByProject(this.choosedContractItem.id);
              }
            }
          });
        this.checkFirstGetWork = false;
      }
    },

    //获取项目的详情
    async getProDetail(id) {
      let [data] = await projectManagementApi.getProjectDetail({ id });
      if (!data) return;
      this.memberSelected = data.worker.map(i => i.id);

      this.taskStaff = [];
      data.bo?.length ? (this.taskStaff = this.taskStaff.concat(data.bo)) : null;
      data.pm?.length ? (this.taskStaff = this.taskStaff.concat(data.pm)) : null;
      data.director?.length ? (this.taskStaff = this.taskStaff.concat(data.director)) : null;
      this.ruleForm.code = data.code;
      this.ruleForm.projectName = data.name;
      this.ruleForm.type = data.type;
      this.ruleForm.category = data.category;
      this.ruleForm.sow = data.sow;
      this.dateVal = [data.start, data.end];
      this.ruleForm.directorSelected = data.bo?.length ? Number(data.bo[0].id) : null;
      this.ruleForm.chiefPMSelected = data.pm?.length ? Number(data.pm[0].id) : null;
      this.ruleForm.director = data.director?.length ? Number(data.director[0].id) : null;
      this.ruleForm.ad = data.ad?.length ? Number(data.ad[0].id) : null;
      /*       this.ruleForm.estimateSubjectsCount = data.estimateSubjectsCount;
      this.ruleForm.estimateAverageVisitCount = data.estimateAverageVisitCount;
      this.ruleForm.estimateDataCleanCount = data.estimateDataCleanCount; */

      this.eqForm.estimateSubjectsCount = data.estimateSubjectsCount;
      this.eqForm.estimateAverageVisitCount = data.estimateAverageVisitCount;
      this.eqForm.estimateDataCleanCount = data.estimateDataCleanCount;
      this.taskStaff = this.taskStaff.concat(data.worker);
      this.taskStaff = removeDuplicate(this.taskStaff);
      this.showMemberSelectedName = data.worker.map(i => i.name);

      // 获取合同序号
      const [res] = await taskManagementApi.serviceSeqData({ projectId: this.choosedContractItem.id });
      this.projectSeqArr = [...res.seqs];
    },

    //获取选择的那个项目
    async getChoosedContractItem(i, type, bool) {
      this.lastChoosedItem = Object.assign({}, this.choosedContractItem);
      this.choosedContractItem = Object.assign({}, i);
      this.contractNames = this.choosedContractItem?.contractNames?.length ? JSON.parse(JSON.stringify(this.choosedContractItem.contractNames)) : [];
      let [data] = await projectManagementApi.getServiceByProject({
        projectIdEq: this.choosedContractItem.id,
        hasChildren: false,
      });
      this.workServiceOptions = [...data.servings];
      this.fixedWorkServiceOptions = [...data.servings];
      if (bool) {
        this.checkFirstGetWork = false;
        return;
      }
      await this.getProDetail(this.choosedContractItem.id);
      if (this.ruleForm.type + '' !== 'null' && this.ruleForm.type !== '') {
        resourceManagementApi.getServiceData({ page: 1, pagesize: 99999, fullCategoryEq: this.ruleForm.type }).then(res => {
          this.resourceOptions = [...res[0].serviceItems];
        });
      } else {
        this.resourceOptions = [];
      }
      this.checkFirstGetWork = false;
      if (this.typeHeaderModule === this.$t('projectManagement.editWorkItem')) {
        // 在项目服务项刷新的同时，重置和清空项目工作项的缓存，使其在切换标签时重新请求
        await this.getWorkByProject(this.choosedContractItem.id);
      } else {
        await this.getServiceByProject(this.choosedContractItem.id);
      }
      this.$refs['ruleForm'].clearValidate();
    },
    //添加项目触发更新
    addProject() {
      this.getProject();
    },

    //撤销更改
    cancelEdit() {
      this.getProDetail(this.choosedContractItem.id);
      this.$message.info('已取消修改,并重置数据状态');
    },
    async saveFormEdit(formName) {
      if (!this.choosedContractItem.id) {
        this.$message.error('请先选择具体项目后再进行操作');
        return;
      }
      const res = await projectManagementApi.verifyUniqueness({ id: this.choosedContractItem.id, name: this.ruleForm.projectName });
      if (!res[0].valid) {
        this.projectNameError = true;
      }
      this.$refs.ruleForm.validate(async valid => {
        if (!valid) {
          this.isRequired && this.ruleForm.type ? null : this.$message.warning('该项目的项目类型为必填，请填写后再次提交保存');
          this.projectNameError = false;
          return;
        }
        if ((!this.ruleForm.projectName && !this.projectNameError) || !this.ruleForm.directorSelected || !this.ruleForm.chiefPMSelected) {
          this.$message.error('有必填信息未填！');
          return;
        }

        let q = {
          id: this.choosedContractItem.id,
          name: this.ruleForm.projectName,
          start: this.dateVal[0] ? dayFormat(this.dateVal[0], 'YYYY-MM-DD') : '',
          end: this.dateVal[1] ? dayFormat(this.dateVal[1], 'YYYY-MM-DD') : '',
          bo: this.ruleForm.directorSelected ? [this.ruleForm.directorSelected] : [],
          pm: this.ruleForm.chiefPMSelected ? [this.ruleForm.chiefPMSelected] : [],
          type: this.ruleForm.type,
          category: this.ruleForm.category,
          sow: this.ruleForm.sow,
          ad: this.ruleForm.ad ? [this.ruleForm.ad] : [],
          director: this.ruleForm.director ? [this.ruleForm.director] : [],
          code: this.ruleForm.code,
          worker: this.memberSelected,
          estimateSubjectsCount: this.eqForm.estimateSubjectsCount,
          estimateAverageVisitCount: this.eqForm.estimateAverageVisitCount,
          estimateDataCleanCount: this.eqForm.estimateDataCleanCount,
        };

        this.$refs[formName].validate(async valid => {
          if (valid) {
            let [data] = await projectManagementApi.getSaveProjectEdit(q);
            if (!data || Object.keys(data).length === 0) {
              this.$message.error('保存失败！');
              return;
            }
            this.$message.success('保存成功！');
            this.ruleForm.projectName = data.name;
            this.dateVal = [data.start, data.end];

            this.memberSelected = data.worker.map(i => i.id);
            this.ruleForm.directorSelected = data.bo?.length ? Number(data.bo[0].id) : '';
            this.ruleForm.chiefPMSelected = data.pm?.length ? Number(data.pm[0].id) : '';
            this.ruleForm.type = data.type;
            this.ruleForm.category = data.category;
            this.ruleForm.sow = data.sow;
            this.ruleForm.ad = data.ad?.length ? Number(data.ad[0].id) : '';
            this.eqForm.estimateSubjectsCount = data.estimateSubjectsCount;
            this.eqForm.estimateAverageVisitCount = data.estimateAverageVisitCount;
            this.eqForm.estimateDataCleanCount = data.estimateDataCleanCount;
            this.ruleForm.director = data.director?.length ? Number(data.director[0].id) : '';
            this.showMemberSelectedName = data.worker.map(i => i.name);
            await this.getProject(false);
          } else {
            return false;
          }
        });
      });
    },
    //保存编辑后的项目
    async saveEdit(formName) {
      if (this.routeName === '项目管理' && this.ruleForm.category === 'DM') {
        for (let key in this.eqForm) {
          if (!this.eqForm[key]) {
            this.$message.error('请点击上方“预估数量”进行填写');
            return;
          }
        }
      }
      if (this.updatedTaskData.length) {
        this.$confirm(this.$t('projectManagement.confirmToLeave'), this.$t('projectManagement.note'), {
          confirmButtonText: this.$t('commonVariables.complete'),
          cancelButtonText: this.$t('commonVariables.cancel'),
        })
          .then(async () => {
            this.saveFormEdit(formName);
          })
          .catch(() => {
            this.$message.info('已取消修改！');
          });
      } else {
        this.saveFormEdit(formName);
      }
    },
    async editTaskDrawer(row) {
      this.taskRow = Object.assign({}, row);
      this.drawerType = 'edit';
      this.drawerVisible = true;
    },
    async editShowDialog(type, serviceId) {
      this.serviceRowId = serviceId;
      let templateForm = null;
      for (let i = 0; i < this.serviceTableData.length; i++) {
        if (this.serviceTableData[i].children?.length) {
          const getItem = this.serviceTableData[i].children.find(val => {
            return val.id == serviceId;
          });
          if (getItem) {
            templateForm = Object.assign({}, getItem);
            break;
          } else if (this.serviceTableData[i].id == serviceId) {
            templateForm = Object.assign({}, this.serviceTableData[i]);
            break;
          }
        } else if (this.serviceTableData[i].id == serviceId) {
          templateForm = Object.assign({}, this.serviceTableData[i]);
          break;
        }
      }
      // type表示调用的是编辑服务项，无type表示的是编辑任务项
      if (type) {
        this.serviceForm = Object.assign({}, templateForm);
        this.parentServiceId = templateForm?.parentId ? templateForm.parentId : '';
        this.dialogTitle = this.$t('projectManagement.editServiceItem');
        if (this.routeName === '项目管理') {
          this.serviceDialogStatus = 'edit';
        }
        this.dialogFormVisible = true;
        return;
      }
      this.detailServiceForm = Object.assign({}, templateForm);
      this.isDeleteArr = [...templateForm.tasks];
      this.dialogDetailFormVisible = true;
    },
    //添加服务项
    addServiceItem() {
      this.dialogTitle = this.$t('projectManagement.createNewServiceItem');
      console.log(this.routeName);

      if (this.routeName === '项目管理') {
        this.serviceDialogStatus = 'add';
        this.serviceForm.projectCode = 'unsigned';
        this.serviceForm.projectSeq = 'unsigned';
      } else {
        this.serviceForm.disabledEdit = true;
      }
      this.dialogFormVisible = true;
    },
    addServiceDetail() {
      this.isDeleteArr.push({
        itemId: null,
        id: null,
        isDeleted: false,
      });
    },
    deleteDetail(idx) {
      this.isDeleteArr.splice(idx, 1);
      /*       this.isDeleteArr = this.isDeleteArr.map((val, index) => {
        if (idx === index) {
          return {
            id: val.id,
            itemId: val.itemId,
            isDeleted: true,
          };
        } else {
          return val;
        }
      }); */
    },
    transformTaskData(taskData) {
      this.updatedTaskData = [...taskData];
    },
    //保存运营管理的服务项或者保存项目管理编辑工作项的更新数据(施工中)
    async saveAllServiceItems() {
      if (this.isWorkItem) {
        if (!this.updatedTaskData.length) {
          this.$message.warning('没有已编辑的工作项');
          return;
        }
        this.updatedTaskData = this.updatedTaskData.map(item => {
          item.itemId = item.itemId ? item.itemId : null;
          return item;
        });
        this.updatedTaskData.forEach(item => {
          if (typeof item.size === 'string') {
            item.size = item.size.trim();
            item.size = item.size === '' ? null : item.size;
          }
        });
        const res = await projectManagementApi.batchUpdateWorkItems({ tasks: this.updatedTaskData });
        if (res[2].status !== 200) {
          this.$alert('保存失败！' + res[1].message, '提示', {
            confirmButtonText: '确定',
            type: 'error',
          });
          return;
        }
        this.$message.success('保存成功！');
        if (this.checkUnit(this.workTableData)) {
          this.warnDialogVisible = true;
        }
        this.checkFirstGetWork = false;
        this.getWorkByProject(this.choosedContractItem.id);
        return;
      }
      if (!this.choosedContractItem.id) {
        this.$message.error('请先选择具体项目后再进行操作');
        return;
      }
      const res = await projectManagementApi.saveServiceItems({ servings: [...this.serviceTableData] }, this.choosedContractItem.id);
      if (res[2].status !== 200) {
        this.$alert('保存失败！' + res[1].message, '提示', {
          confirmButtonText: '确定',
          type: 'error',
        });
        return;
      }
      this.$message.success('保存成功！');
      this.getServiceByProject(this.choosedContractItem.id);
    },
    // 判断是否存在单位不一致问题
    checkUnit(arr) {
      for (let i = 0; i < arr.length; i++) {
        const standardUnit = this.resourceOptions.find(item => {
          return item.id === arr[i].itemId;
        });
        // 数量一致且单位不一致
        if (standardUnit && standardUnit.unit !== arr[i].contractUnit && arr[i].contractSize == arr[i].size) {
          return true;
        }
      }
      return false;
    },
    closeWarnDialog() {
      this.warnDialogVisible = false;
    },

    async deleteRow(deleteId) {
      if (!this.choosedContractItem.id) {
        this.$message.error('请先选择具体项目后再进行操作');
        return;
      }
      let templateForm = null;
      for (let i = 0; i < this.serviceTableData.length; i++) {
        if (this.serviceTableData[i].children?.length) {
          const getItem = this.serviceTableData[i].children.find(val => {
            return val.id == deleteId;
          });
          if (getItem) {
            templateForm = Object.assign({}, getItem);
            break;
          } else if (this.serviceTableData[i].id == deleteId) {
            templateForm = Object.assign({}, this.serviceTableData[i]);
            break;
          }
        } else if (this.serviceTableData[i].id == deleteId) {
          templateForm = Object.assign({}, this.serviceTableData[i]);
          break;
        }
      }
      templateForm.isDeleted = true;
      projectManagementApi.saveServiceItems({ servings: [templateForm] }, this.choosedContractItem.id).then(res => {
        if (res[2].status !== 200) {
          this.$message.error(res[1].message);
          return;
        }
        this.getServiceByProject(this.choosedContractItem.id);
        this.$message.success('删除成功');
      });
    },
    transfromStatus(val) {
      this.projectStatus = val;
      this.getProject(true);
    },
    searchKeyword(keyword) {
      this.projectArrKeyword = keyword;
      this.getProject(true);
    },
  },
};
</script>

<style lang="scss" scoped>
.is-edit-disabled {
  ::v-deep .el-input__inner {
    background-color: #fff !important;
  }
}
::v-deep .el-dialog__header {
  padding-top: 12px;
  padding-bottom: 12px;
  font-weight: 500;
  font-size: 16px;
  justify-content: center;
}
::v-deep.el-select-dropdown__item {
  font-family: Avenir, Helvetica, Arial, sans-serif !important;
}
.project-management__page {
  max-height: 100%;
  display: flex;
  .tree-sub {
    margin-left: 24px;
    .tree-menu {
      ::v-deep .tree-menu__box {
        .tit {
          // height: 68px;
          // line-height: 80px;
          padding-top: 22px;
          margin-bottom: 6px;
        }
      }
    }
  }

  .app-box {
    flex: 1;
    margin: 0 16px;
    background-color: #fff;
    overflow-y: hidden;
    border-radius: 2px;
    width: 100%;
    .header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex: auto;
      padding-bottom: 12px;
      height: 51px;

      .checkHeaderTit {
        cursor: pointer;
        font-size: 14px;
        color: #2d8cf0;
        margin-right: auto;
        padding-left: 10px;
        line-height: 36px;
      }
      .matchbtn {
        color: #1890ff !important;
        background: #fff !important;
        border-color: #70abf8 !important;
      }
      .matchbtn:hover {
        background: #e6f1fc !important;
      }

      .checkHeaderBtn {
        height: 36px;
      }
      .right-container {
        display: flex;
        align-items: center;
        margin-left: auto;
        .expandControl {
          padding-right: 16px;
          color: #409eff;
          cursor: pointer;
          font-size: 14px;
          span {
            padding-right: 5px;
          }
        }
        .header-btns {
          height: 36px;
          font-size: 14px;
          .savebtn {
            margin-left: 16px;
          }
        }
      }
    }

    .top-box {
      background-color: #fff;
      padding: 12px 24px 32px;
      padding-bottom: 8px;
      border-radius: 2px;
      .header {
        border-bottom: 1px solid #e8eaec;
        .top-header-tit {
          font-size: 16px;
          font-weight: 600;
          color: #19233b;
          p {
            display: inline-block;
            margin: 0;
          }
          .tit-container {
            margin: 0;
            display: inline-block;
          }
          .box {
            display: inline-block;
            position: relative;
            // min-width: 92px;
            min-height: 24px;
            margin-left: 8px;

            background: #f5f7fa;
            border-radius: 4px;
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #2d8cf0;

            .circle {
              position: absolute;
              top: calc(50% - 3px);
              left: 12px;
              border-radius: 50%;
              width: 6px;
              height: 6px;
              background: #2d8cf0;
            }
            .currentRord {
              color: #515a6e;

              padding-left: 24px;
              padding-right: 12px;
              line-height: 24px;
            }
          }
          .contractDialog {
            display: inline-block;
            height: 24px;
            .exportIcon {
              vertical-align: middle;
              margin-left: 32px;
              width: 14px;
              height: 14px;
              border: none;
              color: #2d8cf0;
              line-height: 24px;
            }
            .checkContractName {
              font-weight: normal;
              font-size: 14px;
              color: #2d8cf0;
              cursor: pointer;
              margin-left: 6px;
            }
          }
        }
        .tit-container {
          margin: 0;
          display: inline-block;
        }
      }

      .edit_from {
        display: flex;
        flex: auto;
        justify-content: flex-start;
        flex-wrap: wrap;
        font-size: 14px;

        ::v-deep .el-form-item__content {
          line-height: 32px;
        }

        .el-form-item {
          margin: 0;
          flex: auto;
          margin-right: 30px;
          max-width: 220px;
          min-width: 208px;
          padding: 10px 0;
          ::v-deep .el-form-item__label {
            color: #515a6e;
            font-weight: normal;
            font-size: 14px;
            height: 20px;
            padding: 0;
            line-height: 20px;
            margin-top: 0;
            margin-bottom: 7px;
          }
          .projInput {
            width: 230px !important;
            ::v-deep .el-range-input {
              font-size: 12px;
            }
          }
          ::v-deep .el-select {
            width: 100%;
          }
          ::v-deep .el-date-editor--daterange {
            width: 100%;
          }
        }
      }
      /*       .edit_from div:nth-child(-n + 3) {
        margin-bottom: 12px;
      } */
    }
    .heat-shield {
      background: #f8f8f8f9;
      height: 16px;
      width: 100%;
    }
    .bottom-box {
      padding: 12px 24px 12px;
    }
    .serviceEdit {
      ::v-deep .el-dialog__footer {
        padding-right: 32px;
        padding-bottom: 20px;
      }
      .serviceForm {
        padding: 24px 32px 24px 30px;
        ::v-deep .el-input {
          width: 100%;
        }
        ::v-deep .el-form-item__label {
          font-weight: normal;
          color: #515a6e;
        }
        .tasks {
          position: relative;
          ::v-deep .el-form-item__label {
            position: absolute;
            float: none;
            width: 100%;
            padding-right: 10px;
            color: #17233d;
            font-weight: 600;
          }
          ::v-deep .el-form-item__content {
            margin-left: 0 !important;
          }
          .addServiceItem {
            height: 40px;
            display: flex;
            justify-content: right;
            align-items: center;
            // width: 100%;
            .addDetail {
              vertical-align: middle;
              display: inline-block;
              width: 24px;
              height: 24px;
              background: url('~@/assets/tabIcon/addSource.png') no-repeat;
              background-size: 100%;
              cursor: pointer;
            }
            .textDes {
              font-size: 14px;
              display: inline-block;
            }
          }
          .detailFormClass {
            min-height: 40px;
            background: #f8f8f9;
            padding: 5px 0;
            border-radius: 4px;

            .detailItem {
              display: flex;
              padding: 5px 19px 5px 24px;
              align-items: center;
              .idxSlot {
                text-align: right;
                display: inline-block;
                width: 20px;
                margin-right: 12px;
              }
              .deleteDetail {
                vertical-align: middle;
                display: inline-block;
                width: 26px;
                height: 26px;
                background: url('~@/assets/tabIcon/delete_icon.png') no-repeat;
                background-size: 100%;
                cursor: pointer;
                margin-left: 10px;
              }
            }
          }
        }
        .specialItem {
          display: inline-block;
          width: 250px;
        }
        .remarkItem {
          display: inline-block;
          width: 100%;
          .remarkTips {
            padding-left: 10px;
            line-height: 1;
            color: #2d8cf0;
            font-size: 12px;
          }
        }
      }
      .detailServiceForm {
        ::v-deep .el-form-item__label {
          padding-right: 8px;
        }
      }
    }
    .workCreate {
      ::v-deep.el-dialog {
        box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1) !important;
      }
      ::v-deep .el-dialog__footer {
        padding-top: 32px;
        padding-right: 32px;
        padding-bottom: 20px;
      }

      .serviceNameTit {
        width: 100%;
        display: inline-block;
        height: 36px;
        line-height: 36px;
        background: #f8f8f9;
        border-radius: 4px;
        text-indent: 1em; /* 第一行缩进两个空格 */
      }
      .serviceNameTit::before {
        content: '';
        display: inline-block;
        width: 8px;
        height: 8px;
        margin-right: 4px;
        border-radius: 50%;
        background-color: #66b1ff;
      }
      .mainContainer {
        padding: 24px 32px;
        padding-bottom: 0;
        .headerContainer {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          margin-bottom: 15px;
          .el-form-item {
            margin-right: 30px;
            // margin: 0;
            // ::v-deep .el-input__inner {
            //   width: 324px;
            // }
          }
          // .item {
          //   margin-left: 16px;
          // }
        }
        .taskMemberContainer {
          margin-bottom: 0;
          ::v-deep .el-form-item__content {
            margin-left: 0 !important;
          }
          .taskMemberArr {
            min-width: 620px;
            margin: 0;
            .addHeaderContainer {
              display: flex;
              justify-content: space-between;
              align-items: center;
              .left {
                font-weight: 600;
                margin: 0;
                color: #19233b;
              }
              .right {
                margin: 0;
                cursor: pointer;
                .addIcon {
                  color: #1890ff;
                  font-size: 22px;
                  line-height: 28px;
                  vertical-align: middle;
                }
              }
            }
            .addContainer {
              padding: 0 24px;
              padding-top: 8px;

              overflow: hidden;
              margin: 0 auto;
              margin-top: 4px;

              height: 280px;
              background: #f8f8f9;
              border-radius: 4px;
              overflow-y: auto;
              .labelContainer {
                display: flex;
                flex-direction: row;
                // justify-content: space-around;
                p {
                  font-weight: 400;
                  color: #515a6e;
                  line-height: 20px;
                  // flex: auto;
                  margin: 0 !important;
                  padding-left: 146px;
                }
                p:first-child {
                  padding-left: 0;
                }
              }
              .addMainContainer {
                padding: 3px 0;
                width: 100%;
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                align-items: center;

                .authority {
                  padding-right: 16px;
                  flex: auto;
                }

                .member,
                .role {
                  padding-right: 16px;
                  // flex: auto;
                  .inline-input,
                  ::v-deep .el-input__inner {
                    width: 200px;
                  }
                }

                .removeOp {
                  cursor: pointer;
                  color: #1890ff;
                  font-size: 22px;
                  margin-right: 0 auto;
                }
              }
            }
          }
        }
      }
    }
    .checkContractDialog {
      .editRowContainer {
        padding: 32px;
        padding-top: 20px;
        ::v-deep .el-table__header {
          width: 100%;
        }
      }
    }
    .checkEQDialog {
      ::v-deep .el-input {
        width: 208px;
      }
      .eqForm {
        padding: 24px 32px;
        ::v-deep .el-form-item__label {
          font-weight: normal;
        }
      }
    }
    .saveWorkItemTipsDialog {
      ::v-deep .el-dialog__header {
        height: 46px;
        line-height: 45px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid #f0f0f0;
        padding: 0 20px;

        .el-dialog__title {
          color: #17233d;
          font-weight: 600;
        }

        .el-dialog__headerbtn {
          top: 14px;
        }
      }
      ::v-deep .el-dialog__body {
        padding: 20px;

        .tips {
          color: #515a6e;
          font-size: 14px;
          line-height: 24px;
        }

        .tips:nth-child(1) {
          margin-bottom: 10px;
        }
      }
    }
  }

  ::v-deep .el-input__inner {
    color: #515a6e;
    height: 32px;
    line-height: 32px;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    font-size: 14px;
  }
  ::v-deep .el-form-item__error {
    padding-top: 0;
    padding-bottom: 12px;
  }
  ::v-deep .el-input__suffix {
    display: flex;
    align-items: center;
  }

  ::v-deep .el-icon-arrow-up {
    line-height: 32px;
  }
  ::v-deep .el-icon-date {
    line-height: 32px;
  }
}

@media screen and (min-width: 350px) and (max-width: 900px) {
  ::v-deep .tree-menu__box {
    max-width: 188px !important;
  }
}
@media screen and (min-width: 900px) and (max-width: 1550px) {
  ::v-deep .tree-menu__box {
    max-width: 198px !important;
  }
  .tree-sub {
    margin-left: 16px !important;
  }
  .app-box {
    flex: auto !important;
    background-color: #fff;
    padding: 0 !important;
    border-radius: 2px;
    margin: 0 16px !important;
    margin-right: 12px !important;
    margin-top: 0 !important;
  }
  .bottom-box {
    padding: 8px 12px !important;
  }
}
</style>
